* {
	font-family: "Roboto", sans-serif;
}

body {
    background: #EFEFEF;
	&.scroll-hide {
		overflow: hidden;
	}
}

.container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 15px;
}

.page {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    &.main-page {
        .nav-two {
            display: none;
        }
    }
}

.title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #000;
    text-align: center;
}

.subtitle {
    font-weight: 400;
    font-size: 25px;
    line-height: 122.19%;
    text-align: center;
    color: #000000;
    margin-top: 8px;
}

.title-white {
    color: #FFFFFF;
}

.button {
    background: #0D2C7F;
    border-radius: 5px;
    padding: 15px 50px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    margin: 0 auto;
    display: block;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #0936b3;
    }
}


