.promo {
    position: relative;
    padding: 80px 0;
    z-index: 2;
}

.promo__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    object-fit: cover;
}

.promo__wrapper {
    display: flex;
    align-items: center;
    max-width: 1730px;
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
}

.promo-left {
    background: linear-gradient(93.44deg, rgba(0, 46, 163, 0.35) 6.39%, rgba(0, 46, 163, 0.808732) 44.23%, rgba(0, 46, 163, 0.03) 99.54%);
    padding: 10px 30px;
    display: flex;
    align-items: center;
    max-width: 1100px;
    height: fit-content;
    column-gap: 35px;
    padding-right: 5px;
    p {
        font-weight: 700;
        font-size: 41px;
        line-height: 48px;
        text-transform: uppercase;
        color: #F8F8F8;
    }
}

.promo__block {
    background: rgba(255, 255, 255, 0.34);
    backdrop-filter: blur(15px);
    border-radius: 5px;
    padding: 20px;
    width: 526px;
    position: relative;
    z-index: 2;
}

.promo-block__img {
    width: 100%;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
}

.promo-block__title {
    font-size: 20px;
    line-height: 26px;
    margin-top: 15px;
    text-align: center;
    font-weight: 700;
}

.promo-block__subtitle {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #525252;
    max-width: 348px;
    margin: 7px auto 0 auto;
    @include roboto-slab;
    color: #000;
}

.promo-block__link-wrap {
    background: #002EA3;
    box-shadow: 1px 1px 4px rgba(24, 79, 220, 0.35);
    border-radius: 5px;
    padding: 11px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    a {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #FFFFFF;
        &:hover {
            text-shadow: .3px 0 0 currentColor;
        }
    }
}

