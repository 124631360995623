.online-services-page {
    margin-top: 1px;
    .container {
        background-color: #fff;
        padding: 70px 0 140px 0;
    }
}



.online-services-page__title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 25px;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 70px;
}


.accardion {
    max-width: 1103px;
    margin: 0 auto;
}

.accardion__item {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    margin-bottom: 11px;
    &:last-child {
        margin-bottom: 0;
    }
}

.accardion__item--title {
    padding: 25px 30px;
    padding-right: 80px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #000000;
    position: relative;
    padding-right: 70px;
    cursor: pointer;
    &.active {
        &::after {
            transform: translateY(-50%) rotateX(180deg);
        }
    }
    &::after {
        content: '';
        position: absolute;
        background-image: url('../img/arrow-acc.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 26px;
        height: 14px;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease-in-out;
    }

}

.accardion__item--descr {
    font-weight: 400;
    font-size: 20px;
    line-height: 135.69%;
    color: #393939;
    padding: 0 150px 30px 30px;
    display: none;
}