.latest-news {
    margin-top: 100px;
    background: #F8F8F8;
    padding: 55px 0 70px 0;
}

.container {}

.latest-news__wrapper {
    display: grid;
    grid-template-columns: calc(70% - 20px) calc(30% - 20px);
    justify-content: space-between;

}


.latest-news-left {
    .news-sec-item {
        overflow: hidden;
        padding: 26px;
    }
    .news-sec-item__img {
        height: 221px;
        width: 130%;
        transform: translateX(-27px);
        margin-top: -26px;
    }
    .news-sec-item__title {
        height: 23px;
        @include text-hide(1);
        font-size: 19px;
        line-height: 25px;
        text-transform: none;
        text-align: left;
        margin-top: 26px;
    }
    .news-sec-item__descr {
        font-size: 18px;
        line-height: 21px;
        text-align: left;
        margin-top: 15px;
    }
    .news-sec-item__bottom {
        margin-top: 20px;
    }
}

.latest-news-left__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    column-gap: 30px;
    &::before {
        position: absolute;
        content: '';
        width: calc(100% - 165px);
        height: 1px;
        background-color: #E1E1E1;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}


.latest-news__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.015em;
    color: #1B1B1B;
    background: #F8F8F8;
    padding-right: 15px;
}

.latest-news__link {
    background: #EEEEEE;
    border-radius: 4px;
    padding: 16px 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1B1B1B;
}

.latest-news-left__wrapper {
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
}

.latest-news-right {
    .latest-news__title {
        text-align: center;
    }
}


.latest-news-right__wrapper {
    margin-top: 53px;
    background: #EEEEEE;
    border-radius: 10px;
    padding: 25px;
    height: calc(100% - 90px);
}

.latest-news-item {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 12px;
    margin-bottom: 25px;
    transition: all 0.3s ease-in-out;
    display: block;
    &:hover {
        box-shadow: 0px 19px 18px rgb(17 73 214 / 13%);
    }
}

.latest-news-item__descr {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #1B1B1B;
    @include text-hide(2);
}

.latest-news-item__bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.latest-news-right__link {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #1B1B1B;
    display: block;
    margin: 0 auto;
    text-align: center;
}