.map-sec {
    margin-top: 60px;
}

.container {}

.map-sec__title {

}

.map-sec__wrapper {
    margin-top: 50px;
}

.map-sec-block {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
    border-radius: 5px;
    width: 100%;
    padding: 60px 80px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    .contact-bottom {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;
        row-gap: 23px;
    }
    .contact__right {
        width: 790px;
        padding-top: 10px;
        iframe {
            height: 424px;
            border-radius: 44px;
        }
    }
}

.map-sec-block__left {}

.map-sec-block__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    padding-bottom: 18px;
    position: relative;
    &::before {
        position: absolute;
        content: '';
        background-color: #002EA3;
        width: 293px;
        height: 7px;
        left: 0;
        bottom: 0;
    }
}



.contact-bottom-item {}

.contact-bottom-item__icon {}

.contact-bottom-item__right {}

.contact-bottom-item__title {}

.contact__right {}