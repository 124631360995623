.header {
    background: #FFFFFF;
}

.header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.header-top-left {
    display: flex;
    align-items: center;
    column-gap: 25px;
}

.header-top-left__item {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #343434;
    column-gap: 5px;
}

.header-top-right {
    display: flex;
    align-items: center;

}

.header-phone {
    span {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.055em;
        text-transform: uppercase;
        color: #343434;
        display: block;
        margin-bottom: 5px;
    }
    a {
        font-weight: 900;
        font-size: 14px;
        line-height: 16px;
        color: #002EA3;
        display: flex;
        align-items: center;
        column-gap: 8px;
        &::before {
            content: '';
            background-image: url('../img/phone-icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 13px;
            height: 13px;
            display: block;
        }
    }
}

.header-social {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-left: 10px;
    margin-right: 30px;
}

.lang-wrap {
    position: relative;
    &:hover {
        .lang-wrap__list {
            opacity: 1;
            z-index: 20;
        }
    }
    .list-toggle {
        position: relative;
        &::before {
            position: absolute;
            content: url('../img/lang-arrow.svg');
            right: 3px;
            top: 4px;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        color: #000000;
        padding: 6px 14px 6px 6px;
        &:hover {
            background-color: rgb(214, 214, 214);
        }
        img {
            max-width: 18px;
            max-height: 13px;
            margin-right: 4px;
            display: block;
        }
    }
    &__list {
        position: absolute;
        background-color: #fff;
        opacity: 0;
        z-index: -1;
        transition: all .3s;
    }
}


.nav {
    background: #002EA3;
    box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.14);
}

.nav__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1530px;
    width: 100%;
    padding: 20px 15px;
    margin: 0 auto;
}

.nav__item {}

.nav__link {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    &:hover {
        text-shadow: .5px 0 0 currentColor;
        color: #FFFFFF;
    }
    &.active {
        position: relative;
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 4px;
            background-color: #FFFFFF;
            bottom: -8px;
            left: 0;
        }
    }
}

.nav-two {
    background: #FFFFFF;
    padding: 15px;
}

.nav-two__list {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 45px;
}

.nav-two__link {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #343434;
    transition: all 0.3s ease-in-out;
    display: block;
    text-align: center;
    &:hover {
        text-shadow: .5px 0 0 currentColor;
    }
    &.active {
        background: #002EA3;
        border-radius: 25px;
        color: #FFFFFF;
        padding: 5px 8px;
    }
}


.burger {
    height: 16px;
    width: 30px;
    margin-right: 30px;
    justify-content: center;
    align-items: center;
    z-index: 350;
    cursor: pointer;
    transition: all .3s;
    display: none;
    position: relative;
    span {
        position: absolute;
        background-color: #002EA3;
        height: 3px;
        width: 30px;
        display: block;
        transition: all .3s;
        border-radius: 5px;
        &:nth-child(1) {
            top: 16px;
        }
        &:nth-child(2) {
            top: 50%;
            transform: translateY(-50%);
        }
        &:nth-child(3) {
            bottom: 16px;
        }
    }
    &.active {
        span {
            &:nth-child(1) {
                top: 6px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                bottom: 7px;
                transform: rotate(-45deg);
            }
        }
    }
}