.organizations {
    margin-top: 55px;
}

.organizations__wrapper {
    margin: 65px auto 0 auto;
    max-width: 1196px;
    row-gap: 30px;
    display: flex;
    flex-direction: column;
}

.organizations-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    display: flex;
    padding: 10px;
    overflow: hidden;
    position: relative;
    z-index: 2;
    &::before {
        position: absolute;
        content: '';
        background: #F2F2F2;
        height: 100px;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}

.organizations-item__img {
    width: 259px;
    height: 226px;
    margin-right: 25px;
    border-radius: 13px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.organizations-item__center {
    .contact-block {
        margin-top: 37px;
    }
    .contact-block__title {
        text-align: left;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .contact-block__wrapper {
        display: flex;
        column-gap: 20px;
    }

    .site {
        color: #002EA3;
    }
    .contact-block a {
        padding-left: 0;
        display: flex;
        align-items: center;
        line-height: 110%;
    }
    .location {
        align-items: flex-start !important;
        line-height: 154.19% !important;
        margin-top: -5px;
        svg {
            margin-top: 6px;
        }
    }
}

.organizations-item__top {
    font-weight: 700;
    font-size: 20px;
    line-height: 118.19%;
    color: #002EA3;
    padding-top: 5px;
}

.organizations-item__name {
    margin-top: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 118.19%;
    color: #292929;
    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 118.19%;
        color: #070707;
        display: block;
        margin-bottom: 8px;
    }
}



.organizations-item__right {
    width: 298px;
    margin-left: auto;
    background: #002EA3;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        position: absolute;
        content: '';
        width: 110%;
        height: 115%;
        left: 0;
        top: -10px;
        z-index: -1;
        background: #002EA3;
    }
    .reception-days-block__title {
        color: #FFFFFF;
        margin-bottom: 15px;
        font-weight: 700;
    }
    .reception-days-block div {
        color: #FFFFFF;
        span {
            color: #FFFFFF;
        }
    }
}

.reception-days-block {}

.reception-days-block__title {}