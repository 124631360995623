.management {
    margin-top: 55px;
    padding-bottom: 50px;
}

.management__wrapper {
    display: grid;
    margin: 55px auto 0 auto;
    &.management__wrapper--v1 {
        max-width: 1485px;
        grid-template-columns: repeat(5, 1fr);
        gap: 45px;
        .management-item__img {
            overflow: hidden;
            height: 178px;
            border-radius: 16px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .management-item {
            padding: 8px;
        }
        .management-item__name {
            margin-top: 7px;
        }
        .management-item__info {
            border-radius: 0px 0px 16px 16px;
            padding: 20px;
            margin-top: 13px;
        }
        .reception-days-block {
            margin-top: 32px;
        }
    }
    &.management__wrapper--v2 {
        max-width: 1196px;
        grid-template-columns: 1fr;
        gap: 23px;
        .management-item__img {
            overflow: hidden;
            height: 225px;
            border-radius: 16px;
            width: 260px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .management-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            column-gap: 40px;
            overflow: hidden;
        }
        .management-item__info {
            width: calc(100% - 590px);
            height: 100%;
            display: flex;
            padding-top: 80px;
            position: relative;
            z-index: 2;
            &::before {
                position: absolute;
                content: '';
                background: #F2F2F2;
                width: 110%;
                height: 115%;
                left: 0;
                top: -12px;
                z-index: -1;
            }
        }
        .management-item__btn {
            width: 244px;
        }
        .contact-block {
            height: fit-content;
            padding-right: 35px;
            border-right: 1px solid #000000;
            margin-right: 40px;
            padding-left: 40px;
        }
        .contact-block__title {
            text-align: left;
            color: #000000;
            font-weight: 700;
        }
        .contact-block a {
            padding-left: 0;
            text-align: left;
        }
        .reception-days-block__title {
            text-align: left;
            color: #000000;
            font-weight: 700;
        }
        .reception-days-block div {
            text-align: left;
        }
    }
}

.management-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
}

.management-item__name {
    font-weight: 700;
    font-size: 20px;
    line-height: 118.19%;
    text-align: center;
    color: #292929;
    height: 48px;
    @include text-hide(2);
}

.management-item__descr {
    font-weight: 400;
    font-size: 16px;
    line-height: 118.19%;
    text-align: center;
    color: #070707;
    margin-top: 8px;
    height: 38px;
    @include text-hide(2);
}

.management-item__info {
    background: #F2F2F2;
}

.contact-block {
    .svg {
        width: 14px;
        height: 14px;
        margin-right: 7px;
    }
    a {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 76.19%;
        text-transform: capitalize;
        color: #000000;
        margin-bottom: 7px;
        text-align: center;
    }
}
.contact-block__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 76.19%;
    color: #1149D6;
    text-align: center;
    margin-bottom: 11px;
    display: block;
}



.reception-days-block {
    height: 55px;
    div {
        font-weight: 400;
        font-size: 16px;
        line-height: 76.19%;
        text-transform: capitalize;
        color: #1D1E1F;
        text-align: center;
        margin-bottom: 9px;
        &:last-child {
            margin-bottom: 0;
        }
        span {
            color: #1149D6;
            font-weight: 700;
        }
    }
}

.reception-days-block__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 76.19%;
    color: #1149D6;
    text-align: center;
    margin-bottom: 11px;
    display: block;
}

.management-item__btn {
    width: 100%;
    background: #002EA3;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    height: 40px;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #00237c;
    }
}


.modal-wrap-management {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2000;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.modal-management {
    @include scroll-hide;
    overflow-y: auto;
    height: fit-content;
    min-width: 849px;
    max-width: 849px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 95vh;
}

.modal-management__body {
    background: #FFFFFF;
    box-shadow: 5px 10px 23px rgba(13, 44, 127, 0.53);
    border-radius: 15px;
    padding: 40px 50px;
    font-weight: 400;
    font-size: 20px;
    line-height: 143%;
    color: #222222;
}

.modal-management__close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    svg {
        width: 100%;
        height: 100%;
    }
}
