.partners {
    padding: 60px 0;
}

.partners__wrapper {
    margin-top: 60px;
}


.swiper-slide {
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}