.legal-acts-top {
    height: 420px;
    position: relative;
    z-index: 2;
    padding: 88px 0 195px 0;
    .legal-acts-top-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: -1;
    }
    .container {
        max-width: 815px;
    }
}

.legal-acts-top__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 19px;
}

.legal-acts-top__descr {
    font-weight: 400;
    font-size: 25px;
    line-height: 100.19%;
    text-align: center;
    margin-top: 20px;
    color: #FFFFFF;
}


.legal-acts-accardion {
    width: 100%;
    margin-top: -130px;
    z-index: 5;
    .accardion {
        max-width: 100%;
    }
    .accardion__item {
        border: none;
        border-radius: 0;
        box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.05);
        margin-bottom: 0;
        &:first-child {
            .accardion__item--title {
                border-radius: 10px 10px 0px 0px;
            }
        }
    }
    .accardion__item--title {
        background: #FFFFFF;
        border: 1px solid #F9F7F7;
    }
    .accardion__item--descr {
        padding: 0;
    }
    .accardion__item--title::after {
        background-image: url('../img/arrow-acc-two.svg');
        width: 16px;
        height: 7px;
    }
}

.legal-acts-item {
    background: #F4F4F4;
    border: 1px solid #E6E6E6;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.05);
    padding: 20px 30px;
    &:last-child {
        border-bottom: none;
    }
}

.legal-acts-item--done {
    background: #DFE2E6;
    .legal-acts-item__btn {
        background: #002EA3;
        border: 1px solid #002EA3;
        color: #fff;
        pointer-events: none;
    }
}

.legal-acts-item__top {
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
}

.legal-acts-item__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #404040;
    align-items: center;
    display: flex;
    column-gap: 18px;
}

.legal-acts-item__btn {
    border: 1px solid #656565;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #464646;
    padding: 10px 20px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background-color: #fff;
    }
}

.status {
    background: #FFFFFF;
    border-radius: 6.5px;
    max-width: 1157px;
    height: 13px;
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    &.status-show {
        display: block;
    }
    &.status-hide {
        display: none;
    }
    span {
        background: #AFB1B7;
        border-radius: 6.5px;
        height: 100%;
        display: block;
    }
}

