.footer {
    background: #010E3C;
    margin-top: auto;
}


.footer-top {
    padding: 40px 15px 60px 15px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.footer-top-list {
    max-width: 700px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 310px);
    justify-content: space-between;
    margin-bottom: -10px;
    column-gap: 20px;
    a {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #FFFFFF;
        transition: all 0.3s ease-in-out;
        &:hover {
            text-shadow: .5px 0 0 currentColor;
        }
    }
}


.footer-right {
    max-width: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 35px;
    p {
        width: 200px; 
        font-weight: 300;
        font-size: 18px;
        line-height: 223.69%;
        color: #FFFFFF;
        a {
            color: #FFFFFF;
        }
    }
}

.footer-social {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    margin-left: auto;
    width: 233px;
    height: fit-content;
    padding-bottom: 25px;
    span {
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        display: block;
        margin-bottom: 20px;
        width: 100%;
        height: fit-content;
    }
    a {
        transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 0.7;
        }
    }
}

.footer-bottom {
    padding: 30px 0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    border-top: 1px solid rgba(255, 255, 255, 0.09);
}