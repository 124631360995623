.gallery {
    margin-top: 30px;
    .container {
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
        border-radius: 5px;
        max-width: 1438px;
        padding: 35px 50px 70px 50px;
        .swiper {
            max-width: 1148px;
            margin: 0 auto;
        }
        .swiper-slide {
            border-radius: 15px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            width: 62px;
            height: 62px;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 0.6;
            }
            &::after {
                font-size: 0;
            }
        }
        .swiper-button-prev {
            content: url('../img/arrow-prev.svg');
        }
        .swiper-button-next {
            content: url('../img/arrow-next.svg');
        }
    }
}


.gallery__wrapper {
    position: relative;
    margin-top: 40px;
}
