.news-sec {
    padding-top: 50px;
}

.news-sec__top {
    display: grid;
    column-gap: 40px;
    justify-content: space-between;
    grid-template-columns: 18% 49% 28%;
}

.news-sec-filter {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
    border-radius: 5px;
    padding: 25px 30px 45px;
    height: fit-content;
}


.news-sec-filter__title {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #111111;
    margin-bottom: 25px;
}

.news-sec-filter__input {
    margin-bottom: 14px;
    span {
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #161616;
    }
    input {
        background: #FFFFFF;
        border: 1px solid #114BDE;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
        border-radius: 5px;
        width: 100%;
        height: 38px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #474747;
        padding: 0 15px;
        margin-top: 5px;
        &::placeholder {
            color: #B9B9B9;
        }
    }
}

.news-sec-filter__btn {
    padding: 10px;
    width: 100%;
    margin-top: 30px;
    background: #002EA3;
}


.news-sec__wrapper {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 40px;
}

.news-sec-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
    border-radius: 5px;
    padding: 13px;
    transition: all 0.3s ease-in-out;
    display: block;
    &:hover {
        box-shadow: 0px 29px 18px rgba(17, 73, 214, 0.13);
    }
}

.news-sec-item__img {
    width: 100%;
    height: 159px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
}

.news-sec-item__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    height: 42px;
    @include text-hide(2);
    margin-top: 17px;
}

.news-sec-item__descr {
    font-weight: 300;
    font-size: 16px;
    line-height: 124.19%;
    text-align: center;
    color: #2E2E2E;
    margin-top: 10px;
    height: 58px;
    @include text-hide(3);
}

.news-sec-item__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}

.news-sec-item__date {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    display: flex;
    align-items: center;
    column-gap: 6px;
    &::before {
        content: '';
        background-image: url('../img/date.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 12px;
        height: 12px;
        display: block;
    }
}

.news-sec-item__btn {
    background: #E8EEFF;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    width: 145px;
    height: 32px;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #4F4F4F;
    display: flex;
    justify-content: center;
    align-items: center;
}

.news-sec__btn {
    margin-top: 50px;
}


.pagination {
    margin: 80px auto 0 auto;
    display: flex;
    column-gap: 8px;
    width: fit-content;
    a, span {
        background: #FFFFFF;
        border-radius: 3px;
        width: 47px;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        text-align: center;
        color: #000000;
        transition: all 0.3s ease-in-out;
        &.active {
            background: #0D2C7F;
            color: #fff;
        }
    }
    a {
        &:hover {
            background: #ececec;
        }
    }
}


.news-sec-item--big {
    padding: 50px;
    max-width: 1438px;
    margin: 35px auto 0 auto;
    &:hover {
        box-shadow: none;
    }
    .news-sec-item__img {
        height: auto;
    }
    .news-sec-item__title {
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        display: block;
        height: auto;
        text-transform: none;
        margin-top: 40px;
    }
    .news-sec-item__descr {
        display: block;
        height: auto;
        font-weight: 300;
        font-size: 20px;
        line-height: 143.19%;
        text-align: left;
        margin-top: 55px;
        b {
            font-weight: 700;
        }
    }
    .news-sec-item__date {
        font-size: 20px;
        line-height: 23px;
    }
    .news-sec-item__date::before {
        width: 20px;
        height: 20px;
    }
    
}

.news-sec-item__view {
    margin-left: auto;
    font-weight: 500;
    font-size: 20px;
    line-height: 124.19%;
    color: #002EA3;
    display: flex;
    align-items: center;
    margin-right: 40px;
    &::before {
        content: '';
        background-image: url('../img/view.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 22px;
        height: 17px;
        display: block;
        margin-right: 6px;
    }
}

.news-sec-item__share {
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #000000;
    display: flex;
    align-items: center;
    &::before {
        content: '';
        background-image: url('../img/share.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 18px;
        height: 18px;
        display: block;
        margin-right: 6px;
    }
}