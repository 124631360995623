.open-data {
    padding-top: 60px;
    position: relative;
    z-index: 2;
    &::before {
        position: absolute;
        content: '';
        border-top: 1px dashed #002EA3;
        bottom: 31px;
        width: 100%;
        left: 0;
        z-index: -1;
    }
    &.hide {
        &::before {
            display: none;
        }
    }
}

.open-data-chart {
    max-width: 1513px;
    margin: 100px auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 30px;
    .my-chart {
        max-width: 500px;
        max-height: 500px;
        box-shadow: 5px 4px 14px rgba(17, 73, 214, 0.29);
        border-radius: 50%;
        border: 5px solid #fff;
    }
    
}

.open-data-chart__column {
    max-width: 340px;
    width: 100%;
}

.open-data-chart__item {
    background: #FFFFFF;
    border-width: 3px;
    border-style: solid;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 100%;
    height: 95px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 55px;
    max-width: 340px;
    &:last-child {
        margin-bottom: 0;
    }
}

.open-data-chart__item--title {
    font-weight: 400;
    font-size: 17px;
    line-height: 122.19%;
    text-align: center;
    color: #000000;
}

.open-data-chart__item--num {
    font-weight: 700;
    font-size: 26px;
    line-height: 122%;
    text-align: center;
    color: #000000;
    margin-top: 7px;
}

.open-data-chart__item--bottom {
    margin: 90px auto 80px auto;
}

.open-data__btn {
    margin: 0 auto 0 auto;
    background: #002EA3;
    padding: 20px 70px;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    display: block;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #003acc;
    }
}

.more-statistic {
    padding: 45px 15px;
    background-color: #F8F8F8;
    display: none;
    margin-top: 55px;
}

.more-statistic-block__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #000000;
}

.more-statistic-block__subtitle {
    font-weight: 400;
    font-size: 25px;
    line-height: 122.19%;
    text-align: center;
    color: #000000;
    margin-top: 7px;
}

.more-statistic-block__num {
    font-weight: 700;
    font-size: 36px;
    line-height: 122.19%;
    text-align: center;
    color: #002EA3;
    margin-top: 7px;
}

.more-statistic-block__wrapper {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
}

.more-statistic-block-v2 {
    .more-statistic-item {
        background: #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
        border-radius: 16px 77px 16px 16px;
        width: 234px;
        height: 297px;
        padding: 45px 25px 30px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        &.purple {
            border: 3px solid #A645F3;
        }
        &.green {
            border: 3px solid #0CAE60;
        }
        &.red {
            border: 3px solid #C9450D;
        }
        &.blue {
            border: 3px solid #0AA9BE;
        }
        &.yellow {
            border: 3px solid #CACE01;
        }
    }

    .more-statistic-item__title {
        height: 72px;
        font-weight: 400;
        font-size: 18px;
        line-height: 122.19%;
        text-align: center;
        color: #000000;
        display: flex;
        align-items: center;
    }

    .more-statistic-item__num {
        font-weight: 400;
        font-size: 45px;
        line-height: 122.19%;
        text-align: center;
        color: #000000;
    }
}

.more-statistic-block-v3 {
    .more-statistic-block__wrapper {
        gap: 25px;
    }
}

.more-statistic-item {
    background: #FFFFFF;
    border: 3px solid #1149D6;
    box-sizing: border-box;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    padding: 35px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 234px;
    height: 293px;
}

.more-statistic-item__icon {
    height: 100px;
    display: flex;
    align-items: center;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.more-statistic-item__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 122.19%;
    text-align: center;
    color: #000000;
    height: 48px;
    display: flex;
    align-items: center;
}

.more-statistic-item__num {
    font-weight: 400;
    font-size: 45px;
    line-height: 122.19%;
    text-align: center;
    color: #000000;
}

.more-statistic-block {
    margin-bottom: 95px;
    &:last-child {
        margin-bottom: 0;
    }
}
