:root {
  --color-black: #000;
  --color-white: #fff;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Light.ttf") format("ttf");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Regular.ttf") format("ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Medium.ttf") format("ttf");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto-Bold.ttf") format("ttf");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "RobotoSlab";
  src: url("../fonts/RobotoSlab-Regular.ttf") format("ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "RobotoSlab";
  src: url("../fonts/RobotoSlab-Bold.ttf") format("ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

body {
  box-sizing: border-box;
}

aside,
nav,
footer,
header,
section {
  display: block;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

ul li {
  list-style: none;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

label {
  margin: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

input:not([type=checkbox]):not([type=radio]),
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

* {
  font-family: "Roboto", sans-serif;
}

body {
  background: #EFEFEF;
}
body.scroll-hide {
  overflow: hidden;
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 15px;
}

.page {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.page.main-page .nav-two {
  display: none;
}

.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000;
  text-align: center;
}

.subtitle {
  font-weight: 400;
  font-size: 25px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
  margin-top: 8px;
}

.title-white {
  color: #FFFFFF;
}

.button {
  background: #0D2C7F;
  border-radius: 5px;
  padding: 15px 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #FFFFFF;
  margin: 0 auto;
  display: block;
  transition: all 0.3s ease-in-out;
}
.button:hover {
  background: #0936b3;
}

.header {
  background: #FFFFFF;
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
}

.header-top-left {
  display: flex;
  align-items: center;
  column-gap: 25px;
}

.header-top-left__item {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #343434;
  column-gap: 5px;
}

.header-top-right {
  display: flex;
  align-items: center;
}

.header-phone span {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  color: #343434;
  display: block;
  margin-bottom: 5px;
}
.header-phone a {
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  color: #002EA3;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.header-phone a::before {
  content: "";
  background-image: url("../img/phone-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 13px;
  height: 13px;
  display: block;
}

.header-social {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-left: 10px;
  margin-right: 30px;
}

.lang-wrap {
  position: relative;
}
.lang-wrap:hover .lang-wrap__list {
  opacity: 1;
  z-index: 20;
}
.lang-wrap .list-toggle {
  position: relative;
}
.lang-wrap .list-toggle::before {
  position: absolute;
  content: url("../img/lang-arrow.svg");
  right: 3px;
  top: 4px;
}
.lang-wrap__link {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  color: #000000;
  padding: 6px 14px 6px 6px;
}
.lang-wrap__link:hover {
  background-color: #d6d6d6;
}
.lang-wrap__link img {
  max-width: 18px;
  max-height: 13px;
  margin-right: 4px;
  display: block;
}
.lang-wrap__list {
  position: absolute;
  background-color: #fff;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s;
}

.nav {
  background: #002EA3;
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.14);
}

.nav__list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1530px;
  width: 100%;
  padding: 20px 15px;
  margin: 0 auto;
}

.nav__link {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  transition: all 0.3s ease-in-out;
}
.nav__link:hover {
  text-shadow: 0.5px 0 0 currentColor;
  color: #FFFFFF;
}
.nav__link.active {
  position: relative;
}
.nav__link.active::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 4px;
  background-color: #FFFFFF;
  bottom: -8px;
  left: 0;
}

.nav-two {
  background: #FFFFFF;
  padding: 15px;
}

.nav-two__list {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 45px;
}

.nav-two__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #343434;
  transition: all 0.3s ease-in-out;
  display: block;
  text-align: center;
}
.nav-two__link:hover {
  text-shadow: 0.5px 0 0 currentColor;
}
.nav-two__link.active {
  background: #002EA3;
  border-radius: 25px;
  color: #FFFFFF;
  padding: 5px 8px;
}

.burger {
  height: 16px;
  width: 30px;
  margin-right: 30px;
  justify-content: center;
  align-items: center;
  z-index: 350;
  cursor: pointer;
  transition: all 0.3s;
  display: none;
  position: relative;
}
.burger span {
  position: absolute;
  background-color: #002EA3;
  height: 3px;
  width: 30px;
  display: block;
  transition: all 0.3s;
  border-radius: 5px;
}
.burger span:nth-child(1) {
  top: 16px;
}
.burger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
.burger span:nth-child(3) {
  bottom: 16px;
}
.burger.active span:nth-child(1) {
  top: 6px;
  transform: rotate(45deg);
}
.burger.active span:nth-child(2) {
  opacity: 0;
}
.burger.active span:nth-child(3) {
  bottom: 7px;
  transform: rotate(-45deg);
}

.promo {
  position: relative;
  padding: 80px 0;
  z-index: 2;
}

.promo__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  object-fit: cover;
}

.promo__wrapper {
  display: flex;
  align-items: center;
  max-width: 1730px;
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}

.promo-left {
  background: linear-gradient(93.44deg, rgba(0, 46, 163, 0.35) 6.39%, rgba(0, 46, 163, 0.808732) 44.23%, rgba(0, 46, 163, 0.03) 99.54%);
  padding: 10px 30px;
  display: flex;
  align-items: center;
  max-width: 1100px;
  height: fit-content;
  column-gap: 35px;
  padding-right: 5px;
}
.promo-left p {
  font-weight: 700;
  font-size: 41px;
  line-height: 48px;
  text-transform: uppercase;
  color: #F8F8F8;
}

.promo__block {
  background: rgba(255, 255, 255, 0.34);
  backdrop-filter: blur(15px);
  border-radius: 5px;
  padding: 20px;
  width: 526px;
  position: relative;
  z-index: 2;
}

.promo-block__img {
  width: 100%;
}
.promo-block__img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.promo-block__title {
  font-size: 20px;
  line-height: 26px;
  margin-top: 15px;
  text-align: center;
  font-weight: 700;
}

.promo-block__subtitle {
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #525252;
  max-width: 348px;
  margin: 7px auto 0 auto;
  font-family: "RobotoSlab", sans-serif;
  color: #000;
}

.promo-block__link-wrap {
  background: #002EA3;
  box-shadow: 1px 1px 4px rgba(24, 79, 220, 0.35);
  border-radius: 5px;
  padding: 11px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.promo-block__link-wrap a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}
.promo-block__link-wrap a:hover {
  text-shadow: 0.3px 0 0 currentColor;
}

.useful-links {
  margin-top: 90px;
}
.useful-links .tab-link-wrapper {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.useful-links .tab {
  background: #EFEFEF;
  border-radius: 10px;
  padding: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1B1B1B;
}
.useful-links .tab-active {
  background-color: #fff;
}
.useful-links .tab-content-wrapper {
  margin-top: 70px;
}
.useful-links .tabs-content {
  display: none;
}

.useful-links__block-v1 {
  grid-template-columns: repeat(6, minmax(200px, 236px));
  justify-content: center;
  gap: 40px;
}
.useful-links__block-v1.tabs-content-active {
  display: grid !important;
}
.useful-links__block-v1 .useful-links-item {
  background: #fff;
  border-radius: 10px;
  padding: 55px 20px 40px;
  transition: all 0.3s ease-in-out;
}
.useful-links__block-v1 .useful-links-item:hover {
  background: #0D48CF;
}
.useful-links__block-v1 .useful-links-item:hover .useful-links-item__title {
  color: #fff;
}
.useful-links__block-v1 .useful-links-item:hover .useful-links-item__icon {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.useful-links__block-v1 .useful-links-item:hover .useful-links-item__icon::before {
  content: url("../img/useful-links-item__icon2.svg");
}
.useful-links__block-v1 .useful-links-item__title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  transition: all 0.3s ease-in-out;
}
.useful-links__block-v1 .useful-links-item__icon {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  position: relative;
  transition: all 0.3s ease-in-out;
}
.useful-links__block-v1 .useful-links-item__icon::before {
  position: absolute;
  content: url("../img/useful-links-item__icon.svg");
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.useful-links__block-v1 .useful-links-item__icon img {
  width: 70px;
  height: 70px;
}

.useful-links__block-v2 {
  grid-template-columns: repeat(3, minmax(250px, 390px));
  justify-content: center;
  gap: 40px;
}
.useful-links__block-v2.tabs-content-active {
  display: grid !important;
}
.useful-links__block-v2 .useful-links-item {
  background: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  min-height: 80px;
}
.useful-links__block-v2 .useful-links-item:hover {
  background: #0D48CF;
  color: #fff;
}

.open-data {
  padding-top: 60px;
  position: relative;
  z-index: 2;
}
.open-data::before {
  position: absolute;
  content: "";
  border-top: 1px dashed #002EA3;
  bottom: 31px;
  width: 100%;
  left: 0;
  z-index: -1;
}
.open-data.hide::before {
  display: none;
}

.open-data-chart {
  max-width: 1513px;
  margin: 100px auto 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 30px;
}
.open-data-chart .my-chart {
  max-width: 500px;
  max-height: 500px;
  box-shadow: 5px 4px 14px rgba(17, 73, 214, 0.29);
  border-radius: 50%;
  border: 5px solid #fff;
}

.open-data-chart__column {
  max-width: 340px;
  width: 100%;
}

.open-data-chart__item {
  background: #FFFFFF;
  border-width: 3px;
  border-style: solid;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  width: 100%;
  height: 95px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-bottom: 55px;
  max-width: 340px;
}
.open-data-chart__item:last-child {
  margin-bottom: 0;
}

.open-data-chart__item--title {
  font-weight: 400;
  font-size: 17px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
}

.open-data-chart__item--num {
  font-weight: 700;
  font-size: 26px;
  line-height: 122%;
  text-align: center;
  color: #000000;
  margin-top: 7px;
}

.open-data-chart__item--bottom {
  margin: 90px auto 80px auto;
}

.open-data__btn {
  margin: 0 auto 0 auto;
  background: #002EA3;
  padding: 20px 70px;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
  display: block;
  transition: all 0.3s ease-in-out;
}
.open-data__btn:hover {
  background: #003acc;
}

.more-statistic {
  padding: 45px 15px;
  background-color: #F8F8F8;
  display: none;
  margin-top: 55px;
}

.more-statistic-block__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
}

.more-statistic-block__subtitle {
  font-weight: 400;
  font-size: 25px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
  margin-top: 7px;
}

.more-statistic-block__num {
  font-weight: 700;
  font-size: 36px;
  line-height: 122.19%;
  text-align: center;
  color: #002EA3;
  margin-top: 7px;
}

.more-statistic-block__wrapper {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.more-statistic-block-v2 .more-statistic-item {
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 16px 77px 16px 16px;
  width: 234px;
  height: 297px;
  padding: 45px 25px 30px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.more-statistic-block-v2 .more-statistic-item.purple {
  border: 3px solid #A645F3;
}
.more-statistic-block-v2 .more-statistic-item.green {
  border: 3px solid #0CAE60;
}
.more-statistic-block-v2 .more-statistic-item.red {
  border: 3px solid #C9450D;
}
.more-statistic-block-v2 .more-statistic-item.blue {
  border: 3px solid #0AA9BE;
}
.more-statistic-block-v2 .more-statistic-item.yellow {
  border: 3px solid #CACE01;
}
.more-statistic-block-v2 .more-statistic-item__title {
  height: 72px;
  font-weight: 400;
  font-size: 18px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
}
.more-statistic-block-v2 .more-statistic-item__num {
  font-weight: 400;
  font-size: 45px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
}

.more-statistic-block-v3 .more-statistic-block__wrapper {
  gap: 25px;
}

.more-statistic-item {
  background: #FFFFFF;
  border: 3px solid #1149D6;
  box-sizing: border-box;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 234px;
  height: 293px;
}

.more-statistic-item__icon {
  height: 100px;
  display: flex;
  align-items: center;
}
.more-statistic-item__icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.more-statistic-item__title {
  font-weight: 400;
  font-size: 18px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
  height: 48px;
  display: flex;
  align-items: center;
}

.more-statistic-item__num {
  font-weight: 400;
  font-size: 45px;
  line-height: 122.19%;
  text-align: center;
  color: #000000;
}

.more-statistic-block {
  margin-bottom: 95px;
}
.more-statistic-block:last-child {
  margin-bottom: 0;
}

.news-sec {
  padding-top: 50px;
}

.news-sec__top {
  display: grid;
  column-gap: 40px;
  justify-content: space-between;
  grid-template-columns: 18% 49% 28%;
}

.news-sec-filter {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
  border-radius: 5px;
  padding: 25px 30px 45px;
  height: fit-content;
}

.news-sec-filter__title {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #111111;
  margin-bottom: 25px;
}

.news-sec-filter__input {
  margin-bottom: 14px;
}
.news-sec-filter__input span {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #161616;
}
.news-sec-filter__input input {
  background: #FFFFFF;
  border: 1px solid #114BDE;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  width: 100%;
  height: 38px;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #474747;
  padding: 0 15px;
  margin-top: 5px;
}
.news-sec-filter__input input::placeholder {
  color: #B9B9B9;
}

.news-sec-filter__btn {
  padding: 10px;
  width: 100%;
  margin-top: 30px;
  background: #002EA3;
}

.news-sec__wrapper {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;
}

.news-sec-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
  border-radius: 5px;
  padding: 13px;
  transition: all 0.3s ease-in-out;
  display: block;
}
.news-sec-item:hover {
  box-shadow: 0px 29px 18px rgba(17, 73, 214, 0.13);
}

.news-sec-item__img {
  width: 100%;
  height: 159px;
}
.news-sec-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.news-sec-item__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  height: 42px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;
  margin-top: 17px;
}

.news-sec-item__descr {
  font-weight: 300;
  font-size: 16px;
  line-height: 124.19%;
  text-align: center;
  color: #2E2E2E;
  margin-top: 10px;
  height: 58px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;
}

.news-sec-item__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.news-sec-item__date {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  column-gap: 6px;
}
.news-sec-item__date::before {
  content: "";
  background-image: url("../img/date.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 12px;
  height: 12px;
  display: block;
}

.news-sec-item__btn {
  background: #E8EEFF;
  box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 145px;
  height: 32px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #4F4F4F;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-sec__btn {
  margin-top: 50px;
}

.pagination {
  margin: 80px auto 0 auto;
  display: flex;
  column-gap: 8px;
  width: fit-content;
}
.pagination a, .pagination span {
  background: #FFFFFF;
  border-radius: 3px;
  width: 47px;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-align: center;
  color: #000000;
  transition: all 0.3s ease-in-out;
}
.pagination a.active, .pagination span.active {
  background: #0D2C7F;
  color: #fff;
}
.pagination a:hover {
  background: #ececec;
}

.news-sec-item--big {
  padding: 50px;
  max-width: 1438px;
  margin: 35px auto 0 auto;
}
.news-sec-item--big:hover {
  box-shadow: none;
}
.news-sec-item--big .news-sec-item__img {
  height: auto;
}
.news-sec-item--big .news-sec-item__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  display: block;
  height: auto;
  text-transform: none;
  margin-top: 40px;
}
.news-sec-item--big .news-sec-item__descr {
  display: block;
  height: auto;
  font-weight: 300;
  font-size: 20px;
  line-height: 143.19%;
  text-align: left;
  margin-top: 55px;
}
.news-sec-item--big .news-sec-item__descr b {
  font-weight: 700;
}
.news-sec-item--big .news-sec-item__date {
  font-size: 20px;
  line-height: 23px;
}
.news-sec-item--big .news-sec-item__date::before {
  width: 20px;
  height: 20px;
}

.news-sec-item__view {
  margin-left: auto;
  font-weight: 500;
  font-size: 20px;
  line-height: 124.19%;
  color: #002EA3;
  display: flex;
  align-items: center;
  margin-right: 40px;
}
.news-sec-item__view::before {
  content: "";
  background-image: url("../img/view.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 22px;
  height: 17px;
  display: block;
  margin-right: 6px;
}

.news-sec-item__share {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #000000;
  display: flex;
  align-items: center;
}
.news-sec-item__share::before {
  content: "";
  background-image: url("../img/share.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 18px;
  height: 18px;
  display: block;
  margin-right: 6px;
}

.contact {
  background: #FFFFFF;
  padding: 70px 0;
  margin-top: 80px;
}
.contact .container {
  max-width: 1700px;
}

.contact__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 100px;
}

.contact__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
  position: relative;
  padding-bottom: 6px;
}
.contact__title::before {
  position: absolute;
  content: "";
  background: #002EA3;
  width: 185px;
  height: 4px;
  left: 0;
  bottom: 0;
}

.contact__subtitle {
  font-weight: 400;
  font-size: 25px;
  line-height: 122.19%;
  color: #000000;
  margin-top: 20px;
}

.input-block {
  margin-top: 24px;
}
.input-block input {
  background: #FFFFFF;
  border: 1px solid #8692A6;
  border-radius: 6px;
  width: 100%;
  height: 65px;
  padding: 0 30px;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #494949;
}
.input-block input:focus {
  border: 1px solid #1565D8;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.title-min {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2B2B2B;
  display: block;
  margin-bottom: 14px;
}

.textarea-block {
  margin-top: 24px;
}
.textarea-block textarea {
  background: #FFFFFF;
  border: 1px solid #8692A6;
  border-radius: 6px;
  width: 100%;
  height: 345px;
  padding: 20px 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #494949;
  resize: none;
}
.textarea-block textarea:focus {
  border: 1px solid #1565D8;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.contact__btn {
  width: 100%;
  background: #002EA3;
  border-radius: 6px;
  height: 65px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFFFFF;
  margin-top: 55px;
  transition: all 0.3s ease-in-out;
}
.contact__btn:hover {
  background: #033fd4;
}

.contact__right {
  padding-top: 76px;
}
.contact__right span {
  font-weight: 500;
  font-size: 26px;
  line-height: 107.69%;
  text-align: center;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 15px;
}
.contact__right span::before {
  content: "";
  background-image: url("../img/marker.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 32px;
  display: block;
}
.contact__right iframe {
  border: 5px solid #002EA3;
  box-sizing: border-box;
  filter: drop-shadow(3px 4px 20px rgba(0, 0, 0, 0.05));
  border-radius: 5px;
  width: 100%;
  height: 715px;
  margin-top: 45px;
}

.contact-bottom {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.contact-bottom-item {
  display: flex;
  align-items: center;
  max-width: 500px;
}
.contact-bottom-item a {
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  text-transform: capitalize;
  color: #343434;
  display: block;
}

.contact-bottom-item__icon {
  background: rgba(0, 46, 163, 0.17);
  border: 13px solid rgba(0, 46, 163, 0.17);
  border-radius: 50%;
  width: 107px;
  min-width: 107px;
  height: 107px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-bottom-item__right {
  border-left: 3px solid #002EA3;
  padding-left: 25px;
  margin-left: 25px;
}

.contact-bottom-item__title {
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-transform: capitalize;
  color: #002EA3;
  margin-bottom: 10px;
}

.contact-page__top {
  position: relative;
  z-index: 2;
  height: 269px;
}
.contact-page__top img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.contact-page__top .container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  height: 100%;
}

.contact-page__title {
  margin-top: 40px;
}

.contact-page .contact__right {
  width: 100%;
}
.contact-page .contact__right iframe {
  width: 100%;
  height: 471px;
  border: 3px solid #002EA3;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.contact-page__wrapper {
  margin-top: 100px;
  padding-bottom: 5px;
}
.contact-page__wrapper .container {
  max-width: 1462px;
  display: flex;
  justify-content: space-between;
}
.contact-page__wrapper .contact-bottom {
  flex-direction: column;
  align-items: flex-start;
  row-gap: 100px;
  margin-top: 0;
  max-height: 527px;
  margin-top: 154px;
}
.contact-page__wrapper .contact__left {
  max-width: 737px;
  width: 100%;
}

.partners {
  padding: 60px 0;
}

.partners__wrapper {
  margin-top: 60px;
}

.swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.footer {
  background: #010E3C;
  margin-top: auto;
}

.footer-top {
  padding: 40px 15px 60px 15px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.footer-top-list {
  max-width: 700px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 310px);
  justify-content: space-between;
  margin-bottom: -10px;
  column-gap: 20px;
}
.footer-top-list a {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  transition: all 0.3s ease-in-out;
}
.footer-top-list a:hover {
  text-shadow: 0.5px 0 0 currentColor;
}

.footer-right {
  max-width: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 35px;
}
.footer-right p {
  width: 200px;
  font-weight: 300;
  font-size: 18px;
  line-height: 223.69%;
  color: #FFFFFF;
}
.footer-right p a {
  color: #FFFFFF;
}

.footer-social {
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  margin-left: auto;
  width: 233px;
  height: fit-content;
  padding-bottom: 25px;
}
.footer-social span {
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  height: fit-content;
}
.footer-social a {
  transition: all 0.3s ease-in-out;
}
.footer-social a:hover {
  opacity: 0.7;
}

.footer-bottom {
  padding: 30px 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  border-top: 1px solid rgba(255, 255, 255, 0.09);
}

.online-services-page {
  margin-top: 1px;
}
.online-services-page .container {
  background-color: #fff;
  padding: 70px 0 140px 0;
}

.online-services-page__title {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
  font-size: 30px;
  line-height: 35px;
  margin-bottom: 70px;
}

.accardion {
  max-width: 1103px;
  margin: 0 auto;
}

.accardion__item {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  margin-bottom: 11px;
}
.accardion__item:last-child {
  margin-bottom: 0;
}

.accardion__item--title {
  padding: 25px 30px;
  padding-right: 80px;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #000000;
  position: relative;
  padding-right: 70px;
  cursor: pointer;
}
.accardion__item--title.active::after {
  transform: translateY(-50%) rotateX(180deg);
}
.accardion__item--title::after {
  content: "";
  position: absolute;
  background-image: url("../img/arrow-acc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 14px;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.accardion__item--descr {
  font-weight: 400;
  font-size: 20px;
  line-height: 135.69%;
  color: #393939;
  padding: 0 150px 30px 30px;
  display: none;
}

.form-sec {
  max-width: 880px;
  margin: 70px auto 0 auto;
}

.form-sec__title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #2F2F2F;
}

.form-sec__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 60px;
  margin-top: 40px;
}
.form-sec__wrapper .input-block input {
  height: 50px;
  padding: 0 20px;
}

.form-sec__title-item {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #696F79;
  display: block;
  margin-bottom: 6px;
}

.radio-wrap {
  margin-top: 23px;
}
.radio-wrap.radio-wrap--v2 > div {
  flex-direction: column;
  align-items: flex-start;
  row-gap: 14px;
}
.radio-wrap.radio-wrap--v2 > div .radio-block.active {
  color: #2F2F2F;
}
.radio-wrap.radio-wrap--v2 > div .radio-block input:checked {
  border: 2px solid #2F2F2F;
}
.radio-wrap.radio-wrap--v2 > div .radio-block input::before {
  background-color: #2F2F2F;
}
.radio-wrap > div {
  background: #F4F4F4;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  column-gap: 35px;
}

.input-block__wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}

.radio-block {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #494949;
  cursor: pointer;
}
.radio-block.active {
  color: #6E41E2;
}
.radio-block input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(255, 255, 255, 0.25);
  border: 1px solid rgba(153, 153, 153, 0.5);
  border-radius: 24px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
}
.radio-block input::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  background: #6E41E2;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.radio-block input:checked {
  border: 2px solid #6E41E2;
}
.radio-block input:checked::before {
  opacity: 1;
}

.select-block {
  margin-top: 23px;
  position: relative;
}
.select-block::before {
  position: absolute;
  content: url("../img/arrow-select.svg");
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.select-block select {
  border: 1px solid #8692A6;
  border-radius: 6px;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  line-height: 168.19%;
  color: #8692A6;
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
}

.phone-input {
  margin-top: 24px;
}
.phone-input input {
  border: 1px solid #8692A6;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #8692A6;
  padding-left: 60px !important;
}
.phone-input .iti {
  width: 100%;
}
.phone-input .iti__selected-flag {
  padding: 0 15px;
}

.file-block {
  margin-top: 23px;
}

.mt-47 {
  margin-top: 48px;
}

.input-file {
  width: 100%;
  height: 50px;
  background: #F5F6F9;
  border: 1px dashed #8692A6;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.input-file.input-file--done {
  background: #EEFFEE;
  border: 1px dashed #209920;
  position: relative;
}
.input-file.input-file--done::before {
  position: absolute;
  content: url("../img/check.svg");
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.input-file.input-file--done p {
  color: #209920;
}
.input-file.input-file--error {
  background: #F9E3E3;
  border: 1px dashed #E70000;
}
.input-file.input-file--error p {
  color: #F25252;
}
.input-file p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #898989;
}
.input-file input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-sec__block-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 6px;
}
.form-sec__block-wrap .input-block {
  margin-top: 0;
}

.data-block {
  border: 1px solid #8692A6;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  column-gap: 7px;
}
.data-block div {
  width: 22px;
  height: 23px;
  background-image: url("../../img/date-icon.svg");
}
.data-block div input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  cursor: pointer;
}
.data-block .data-block__val {
  font-weight: 400;
  font-size: 16px;
  line-height: 168.19%;
  color: #8692A6;
}

[type=date] {
  width: 100%;
  position: relative;
}

[type=date]::-webkit-calendar-picker-indicator {
  width: calc(100% - 0.25em);
  left: -1.5em;
  background-position: 100% 0;
  position: absolute;
  height: 100%;
}

input[type=date]::-webkit-calendar-picker-indicator {
  background: none;
}

.form-sec__bottom {
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-sec__bottom div {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #696F79;
}

.form-sec__btn {
  background: #FFFFFF;
  border: 1px solid #BBBFC1;
  border-radius: 6px;
  padding: 15px 11px;
  display: flex;
  align-items: center;
  column-gap: 7px;
  margin: 65px auto 0 auto;
  transition: all 0.3s ease-in-out;
}
.form-sec__btn:hover {
  background: #ececec;
}
.form-sec__btn::before {
  content: "";
  background-image: url("../../img/save.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  display: block;
}

.iti__flag {
  width: 20px;
}
.iti__flag.iti__be {
  width: 18px;
}
.iti__flag.iti__ch {
  width: 15px;
}
.iti__flag.iti__mc {
  width: 19px;
}
.iti__flag.iti__ne {
  width: 18px;
}
.iti__flag.iti__np {
  width: 13px;
}
.iti__flag.iti__va {
  width: 15px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-size: 5652px 15px;
  }
}
.iti__flag.iti__ac {
  height: 10px;
  background-position: 0px 0px;
}
.iti__flag.iti__ad {
  height: 14px;
  background-position: -22px 0px;
}
.iti__flag.iti__ae {
  height: 10px;
  background-position: -44px 0px;
}
.iti__flag.iti__af {
  height: 14px;
  background-position: -66px 0px;
}
.iti__flag.iti__ag {
  height: 14px;
  background-position: -88px 0px;
}
.iti__flag.iti__ai {
  height: 10px;
  background-position: -110px 0px;
}
.iti__flag.iti__al {
  height: 15px;
  background-position: -132px 0px;
}
.iti__flag.iti__am {
  height: 10px;
  background-position: -154px 0px;
}
.iti__flag.iti__ao {
  height: 14px;
  background-position: -176px 0px;
}
.iti__flag.iti__aq {
  height: 14px;
  background-position: -198px 0px;
}
.iti__flag.iti__ar {
  height: 13px;
  background-position: -220px 0px;
}
.iti__flag.iti__as {
  height: 10px;
  background-position: -242px 0px;
}
.iti__flag.iti__at {
  height: 14px;
  background-position: -264px 0px;
}
.iti__flag.iti__au {
  height: 10px;
  background-position: -286px 0px;
}
.iti__flag.iti__aw {
  height: 14px;
  background-position: -308px 0px;
}
.iti__flag.iti__ax {
  height: 13px;
  background-position: -330px 0px;
}
.iti__flag.iti__az {
  height: 10px;
  background-position: -352px 0px;
}
.iti__flag.iti__ba {
  height: 10px;
  background-position: -374px 0px;
}
.iti__flag.iti__bb {
  height: 14px;
  background-position: -396px 0px;
}
.iti__flag.iti__bd {
  height: 12px;
  background-position: -418px 0px;
}
.iti__flag.iti__be {
  height: 15px;
  background-position: -440px 0px;
}
.iti__flag.iti__bf {
  height: 14px;
  background-position: -460px 0px;
}
.iti__flag.iti__bg {
  height: 12px;
  background-position: -482px 0px;
}
.iti__flag.iti__bh {
  height: 12px;
  background-position: -504px 0px;
}
.iti__flag.iti__bi {
  height: 12px;
  background-position: -526px 0px;
}
.iti__flag.iti__bj {
  height: 14px;
  background-position: -548px 0px;
}
.iti__flag.iti__bl {
  height: 14px;
  background-position: -570px 0px;
}
.iti__flag.iti__bm {
  height: 10px;
  background-position: -592px 0px;
}
.iti__flag.iti__bn {
  height: 10px;
  background-position: -614px 0px;
}
.iti__flag.iti__bo {
  height: 14px;
  background-position: -636px 0px;
}
.iti__flag.iti__bq {
  height: 14px;
  background-position: -658px 0px;
}
.iti__flag.iti__br {
  height: 14px;
  background-position: -680px 0px;
}
.iti__flag.iti__bs {
  height: 10px;
  background-position: -702px 0px;
}
.iti__flag.iti__bt {
  height: 14px;
  background-position: -724px 0px;
}
.iti__flag.iti__bv {
  height: 15px;
  background-position: -746px 0px;
}
.iti__flag.iti__bw {
  height: 14px;
  background-position: -768px 0px;
}
.iti__flag.iti__by {
  height: 10px;
  background-position: -790px 0px;
}
.iti__flag.iti__bz {
  height: 14px;
  background-position: -812px 0px;
}
.iti__flag.iti__ca {
  height: 10px;
  background-position: -834px 0px;
}
.iti__flag.iti__cc {
  height: 10px;
  background-position: -856px 0px;
}
.iti__flag.iti__cd {
  height: 15px;
  background-position: -878px 0px;
}
.iti__flag.iti__cf {
  height: 14px;
  background-position: -900px 0px;
}
.iti__flag.iti__cg {
  height: 14px;
  background-position: -922px 0px;
}
.iti__flag.iti__ch {
  height: 15px;
  background-position: -944px 0px;
}
.iti__flag.iti__ci {
  height: 14px;
  background-position: -961px 0px;
}
.iti__flag.iti__ck {
  height: 10px;
  background-position: -983px 0px;
}
.iti__flag.iti__cl {
  height: 14px;
  background-position: -1005px 0px;
}
.iti__flag.iti__cm {
  height: 14px;
  background-position: -1027px 0px;
}
.iti__flag.iti__cn {
  height: 14px;
  background-position: -1049px 0px;
}
.iti__flag.iti__co {
  height: 14px;
  background-position: -1071px 0px;
}
.iti__flag.iti__cp {
  height: 14px;
  background-position: -1093px 0px;
}
.iti__flag.iti__cr {
  height: 12px;
  background-position: -1115px 0px;
}
.iti__flag.iti__cu {
  height: 10px;
  background-position: -1137px 0px;
}
.iti__flag.iti__cv {
  height: 12px;
  background-position: -1159px 0px;
}
.iti__flag.iti__cw {
  height: 14px;
  background-position: -1181px 0px;
}
.iti__flag.iti__cx {
  height: 10px;
  background-position: -1203px 0px;
}
.iti__flag.iti__cy {
  height: 14px;
  background-position: -1225px 0px;
}
.iti__flag.iti__cz {
  height: 14px;
  background-position: -1247px 0px;
}
.iti__flag.iti__de {
  height: 12px;
  background-position: -1269px 0px;
}
.iti__flag.iti__dg {
  height: 10px;
  background-position: -1291px 0px;
}
.iti__flag.iti__dj {
  height: 14px;
  background-position: -1313px 0px;
}
.iti__flag.iti__dk {
  height: 15px;
  background-position: -1335px 0px;
}
.iti__flag.iti__dm {
  height: 10px;
  background-position: -1357px 0px;
}
.iti__flag.iti__do {
  height: 14px;
  background-position: -1379px 0px;
}
.iti__flag.iti__dz {
  height: 14px;
  background-position: -1401px 0px;
}
.iti__flag.iti__ea {
  height: 14px;
  background-position: -1423px 0px;
}
.iti__flag.iti__ec {
  height: 14px;
  background-position: -1445px 0px;
}
.iti__flag.iti__ee {
  height: 13px;
  background-position: -1467px 0px;
}
.iti__flag.iti__eg {
  height: 14px;
  background-position: -1489px 0px;
}
.iti__flag.iti__eh {
  height: 10px;
  background-position: -1511px 0px;
}
.iti__flag.iti__er {
  height: 10px;
  background-position: -1533px 0px;
}
.iti__flag.iti__es {
  height: 14px;
  background-position: -1555px 0px;
}
.iti__flag.iti__et {
  height: 10px;
  background-position: -1577px 0px;
}
.iti__flag.iti__eu {
  height: 14px;
  background-position: -1599px 0px;
}
.iti__flag.iti__fi {
  height: 12px;
  background-position: -1621px 0px;
}
.iti__flag.iti__fj {
  height: 10px;
  background-position: -1643px 0px;
}
.iti__flag.iti__fk {
  height: 10px;
  background-position: -1665px 0px;
}
.iti__flag.iti__fm {
  height: 11px;
  background-position: -1687px 0px;
}
.iti__flag.iti__fo {
  height: 15px;
  background-position: -1709px 0px;
}
.iti__flag.iti__fr {
  height: 14px;
  background-position: -1731px 0px;
}
.iti__flag.iti__ga {
  height: 15px;
  background-position: -1753px 0px;
}
.iti__flag.iti__gb {
  height: 10px;
  background-position: -1775px 0px;
}
.iti__flag.iti__gd {
  height: 12px;
  background-position: -1797px 0px;
}
.iti__flag.iti__ge {
  height: 14px;
  background-position: -1819px 0px;
}
.iti__flag.iti__gf {
  height: 14px;
  background-position: -1841px 0px;
}
.iti__flag.iti__gg {
  height: 14px;
  background-position: -1863px 0px;
}
.iti__flag.iti__gh {
  height: 14px;
  background-position: -1885px 0px;
}
.iti__flag.iti__gi {
  height: 10px;
  background-position: -1907px 0px;
}
.iti__flag.iti__gl {
  height: 14px;
  background-position: -1929px 0px;
}
.iti__flag.iti__gm {
  height: 14px;
  background-position: -1951px 0px;
}
.iti__flag.iti__gn {
  height: 14px;
  background-position: -1973px 0px;
}
.iti__flag.iti__gp {
  height: 14px;
  background-position: -1995px 0px;
}
.iti__flag.iti__gq {
  height: 14px;
  background-position: -2017px 0px;
}
.iti__flag.iti__gr {
  height: 14px;
  background-position: -2039px 0px;
}
.iti__flag.iti__gs {
  height: 10px;
  background-position: -2061px 0px;
}
.iti__flag.iti__gt {
  height: 13px;
  background-position: -2083px 0px;
}
.iti__flag.iti__gu {
  height: 11px;
  background-position: -2105px 0px;
}
.iti__flag.iti__gw {
  height: 10px;
  background-position: -2127px 0px;
}
.iti__flag.iti__gy {
  height: 12px;
  background-position: -2149px 0px;
}
.iti__flag.iti__hk {
  height: 14px;
  background-position: -2171px 0px;
}
.iti__flag.iti__hm {
  height: 10px;
  background-position: -2193px 0px;
}
.iti__flag.iti__hn {
  height: 10px;
  background-position: -2215px 0px;
}
.iti__flag.iti__hr {
  height: 10px;
  background-position: -2237px 0px;
}
.iti__flag.iti__ht {
  height: 12px;
  background-position: -2259px 0px;
}
.iti__flag.iti__hu {
  height: 10px;
  background-position: -2281px 0px;
}
.iti__flag.iti__ic {
  height: 14px;
  background-position: -2303px 0px;
}
.iti__flag.iti__id {
  height: 14px;
  background-position: -2325px 0px;
}
.iti__flag.iti__ie {
  height: 10px;
  background-position: -2347px 0px;
}
.iti__flag.iti__il {
  height: 15px;
  background-position: -2369px 0px;
}
.iti__flag.iti__im {
  height: 10px;
  background-position: -2391px 0px;
}
.iti__flag.iti__in {
  height: 14px;
  background-position: -2413px 0px;
}
.iti__flag.iti__io {
  height: 10px;
  background-position: -2435px 0px;
}
.iti__flag.iti__iq {
  height: 14px;
  background-position: -2457px 0px;
}
.iti__flag.iti__ir {
  height: 12px;
  background-position: -2479px 0px;
}
.iti__flag.iti__is {
  height: 15px;
  background-position: -2501px 0px;
}
.iti__flag.iti__it {
  height: 14px;
  background-position: -2523px 0px;
}
.iti__flag.iti__je {
  height: 12px;
  background-position: -2545px 0px;
}
.iti__flag.iti__jm {
  height: 10px;
  background-position: -2567px 0px;
}
.iti__flag.iti__jo {
  height: 10px;
  background-position: -2589px 0px;
}
.iti__flag.iti__jp {
  height: 14px;
  background-position: -2611px 0px;
}
.iti__flag.iti__ke {
  height: 14px;
  background-position: -2633px 0px;
}
.iti__flag.iti__kg {
  height: 12px;
  background-position: -2655px 0px;
}
.iti__flag.iti__kh {
  height: 13px;
  background-position: -2677px 0px;
}
.iti__flag.iti__ki {
  height: 10px;
  background-position: -2699px 0px;
}
.iti__flag.iti__km {
  height: 12px;
  background-position: -2721px 0px;
}
.iti__flag.iti__kn {
  height: 14px;
  background-position: -2743px 0px;
}
.iti__flag.iti__kp {
  height: 10px;
  background-position: -2765px 0px;
}
.iti__flag.iti__kr {
  height: 14px;
  background-position: -2787px 0px;
}
.iti__flag.iti__kw {
  height: 10px;
  background-position: -2809px 0px;
}
.iti__flag.iti__ky {
  height: 10px;
  background-position: -2831px 0px;
}
.iti__flag.iti__kz {
  height: 10px;
  background-position: -2853px 0px;
}
.iti__flag.iti__la {
  height: 14px;
  background-position: -2875px 0px;
}
.iti__flag.iti__lb {
  height: 14px;
  background-position: -2897px 0px;
}
.iti__flag.iti__lc {
  height: 10px;
  background-position: -2919px 0px;
}
.iti__flag.iti__li {
  height: 12px;
  background-position: -2941px 0px;
}
.iti__flag.iti__lk {
  height: 10px;
  background-position: -2963px 0px;
}
.iti__flag.iti__lr {
  height: 11px;
  background-position: -2985px 0px;
}
.iti__flag.iti__ls {
  height: 14px;
  background-position: -3007px 0px;
}
.iti__flag.iti__lt {
  height: 12px;
  background-position: -3029px 0px;
}
.iti__flag.iti__lu {
  height: 12px;
  background-position: -3051px 0px;
}
.iti__flag.iti__lv {
  height: 10px;
  background-position: -3073px 0px;
}
.iti__flag.iti__ly {
  height: 10px;
  background-position: -3095px 0px;
}
.iti__flag.iti__ma {
  height: 14px;
  background-position: -3117px 0px;
}
.iti__flag.iti__mc {
  height: 15px;
  background-position: -3139px 0px;
}
.iti__flag.iti__md {
  height: 10px;
  background-position: -3160px 0px;
}
.iti__flag.iti__me {
  height: 10px;
  background-position: -3182px 0px;
}
.iti__flag.iti__mf {
  height: 14px;
  background-position: -3204px 0px;
}
.iti__flag.iti__mg {
  height: 14px;
  background-position: -3226px 0px;
}
.iti__flag.iti__mh {
  height: 11px;
  background-position: -3248px 0px;
}
.iti__flag.iti__mk {
  height: 10px;
  background-position: -3270px 0px;
}
.iti__flag.iti__ml {
  height: 14px;
  background-position: -3292px 0px;
}
.iti__flag.iti__mm {
  height: 14px;
  background-position: -3314px 0px;
}
.iti__flag.iti__mn {
  height: 10px;
  background-position: -3336px 0px;
}
.iti__flag.iti__mo {
  height: 14px;
  background-position: -3358px 0px;
}
.iti__flag.iti__mp {
  height: 10px;
  background-position: -3380px 0px;
}
.iti__flag.iti__mq {
  height: 14px;
  background-position: -3402px 0px;
}
.iti__flag.iti__mr {
  height: 14px;
  background-position: -3424px 0px;
}
.iti__flag.iti__ms {
  height: 10px;
  background-position: -3446px 0px;
}
.iti__flag.iti__mt {
  height: 14px;
  background-position: -3468px 0px;
}
.iti__flag.iti__mu {
  height: 14px;
  background-position: -3490px 0px;
}
.iti__flag.iti__mv {
  height: 14px;
  background-position: -3512px 0px;
}
.iti__flag.iti__mw {
  height: 14px;
  background-position: -3534px 0px;
}
.iti__flag.iti__mx {
  height: 12px;
  background-position: -3556px 0px;
}
.iti__flag.iti__my {
  height: 10px;
  background-position: -3578px 0px;
}
.iti__flag.iti__mz {
  height: 14px;
  background-position: -3600px 0px;
}
.iti__flag.iti__na {
  height: 14px;
  background-position: -3622px 0px;
}
.iti__flag.iti__nc {
  height: 10px;
  background-position: -3644px 0px;
}
.iti__flag.iti__ne {
  height: 15px;
  background-position: -3666px 0px;
}
.iti__flag.iti__nf {
  height: 10px;
  background-position: -3686px 0px;
}
.iti__flag.iti__ng {
  height: 10px;
  background-position: -3708px 0px;
}
.iti__flag.iti__ni {
  height: 12px;
  background-position: -3730px 0px;
}
.iti__flag.iti__nl {
  height: 14px;
  background-position: -3752px 0px;
}
.iti__flag.iti__no {
  height: 15px;
  background-position: -3774px 0px;
}
.iti__flag.iti__np {
  height: 15px;
  background-position: -3796px 0px;
}
.iti__flag.iti__nr {
  height: 10px;
  background-position: -3811px 0px;
}
.iti__flag.iti__nu {
  height: 10px;
  background-position: -3833px 0px;
}
.iti__flag.iti__nz {
  height: 10px;
  background-position: -3855px 0px;
}
.iti__flag.iti__om {
  height: 10px;
  background-position: -3877px 0px;
}
.iti__flag.iti__pa {
  height: 14px;
  background-position: -3899px 0px;
}
.iti__flag.iti__pe {
  height: 14px;
  background-position: -3921px 0px;
}
.iti__flag.iti__pf {
  height: 14px;
  background-position: -3943px 0px;
}
.iti__flag.iti__pg {
  height: 15px;
  background-position: -3965px 0px;
}
.iti__flag.iti__ph {
  height: 10px;
  background-position: -3987px 0px;
}
.iti__flag.iti__pk {
  height: 14px;
  background-position: -4009px 0px;
}
.iti__flag.iti__pl {
  height: 13px;
  background-position: -4031px 0px;
}
.iti__flag.iti__pm {
  height: 14px;
  background-position: -4053px 0px;
}
.iti__flag.iti__pn {
  height: 10px;
  background-position: -4075px 0px;
}
.iti__flag.iti__pr {
  height: 14px;
  background-position: -4097px 0px;
}
.iti__flag.iti__ps {
  height: 10px;
  background-position: -4119px 0px;
}
.iti__flag.iti__pt {
  height: 14px;
  background-position: -4141px 0px;
}
.iti__flag.iti__pw {
  height: 13px;
  background-position: -4163px 0px;
}
.iti__flag.iti__py {
  height: 11px;
  background-position: -4185px 0px;
}
.iti__flag.iti__qa {
  height: 8px;
  background-position: -4207px 0px;
}
.iti__flag.iti__re {
  height: 14px;
  background-position: -4229px 0px;
}
.iti__flag.iti__ro {
  height: 14px;
  background-position: -4251px 0px;
}
.iti__flag.iti__rs {
  height: 14px;
  background-position: -4273px 0px;
}
.iti__flag.iti__ru {
  height: 14px;
  background-position: -4295px 0px;
}
.iti__flag.iti__rw {
  height: 14px;
  background-position: -4317px 0px;
}
.iti__flag.iti__sa {
  height: 14px;
  background-position: -4339px 0px;
}
.iti__flag.iti__sb {
  height: 10px;
  background-position: -4361px 0px;
}
.iti__flag.iti__sc {
  height: 10px;
  background-position: -4383px 0px;
}
.iti__flag.iti__sd {
  height: 10px;
  background-position: -4405px 0px;
}
.iti__flag.iti__se {
  height: 13px;
  background-position: -4427px 0px;
}
.iti__flag.iti__sg {
  height: 14px;
  background-position: -4449px 0px;
}
.iti__flag.iti__sh {
  height: 10px;
  background-position: -4471px 0px;
}
.iti__flag.iti__si {
  height: 10px;
  background-position: -4493px 0px;
}
.iti__flag.iti__sj {
  height: 15px;
  background-position: -4515px 0px;
}
.iti__flag.iti__sk {
  height: 14px;
  background-position: -4537px 0px;
}
.iti__flag.iti__sl {
  height: 14px;
  background-position: -4559px 0px;
}
.iti__flag.iti__sm {
  height: 15px;
  background-position: -4581px 0px;
}
.iti__flag.iti__sn {
  height: 14px;
  background-position: -4603px 0px;
}
.iti__flag.iti__so {
  height: 14px;
  background-position: -4625px 0px;
}
.iti__flag.iti__sr {
  height: 14px;
  background-position: -4647px 0px;
}
.iti__flag.iti__ss {
  height: 10px;
  background-position: -4669px 0px;
}
.iti__flag.iti__st {
  height: 10px;
  background-position: -4691px 0px;
}
.iti__flag.iti__sv {
  height: 12px;
  background-position: -4713px 0px;
}
.iti__flag.iti__sx {
  height: 14px;
  background-position: -4735px 0px;
}
.iti__flag.iti__sy {
  height: 14px;
  background-position: -4757px 0px;
}
.iti__flag.iti__sz {
  height: 14px;
  background-position: -4779px 0px;
}
.iti__flag.iti__ta {
  height: 10px;
  background-position: -4801px 0px;
}
.iti__flag.iti__tc {
  height: 10px;
  background-position: -4823px 0px;
}
.iti__flag.iti__td {
  height: 14px;
  background-position: -4845px 0px;
}
.iti__flag.iti__tf {
  height: 14px;
  background-position: -4867px 0px;
}
.iti__flag.iti__tg {
  height: 13px;
  background-position: -4889px 0px;
}
.iti__flag.iti__th {
  height: 14px;
  background-position: -4911px 0px;
}
.iti__flag.iti__tj {
  height: 10px;
  background-position: -4933px 0px;
}
.iti__flag.iti__tk {
  height: 10px;
  background-position: -4955px 0px;
}
.iti__flag.iti__tl {
  height: 10px;
  background-position: -4977px 0px;
}
.iti__flag.iti__tm {
  height: 14px;
  background-position: -4999px 0px;
}
.iti__flag.iti__tn {
  height: 14px;
  background-position: -5021px 0px;
}
.iti__flag.iti__to {
  height: 10px;
  background-position: -5043px 0px;
}
.iti__flag.iti__tr {
  height: 14px;
  background-position: -5065px 0px;
}
.iti__flag.iti__tt {
  height: 12px;
  background-position: -5087px 0px;
}
.iti__flag.iti__tv {
  height: 10px;
  background-position: -5109px 0px;
}
.iti__flag.iti__tw {
  height: 14px;
  background-position: -5131px 0px;
}
.iti__flag.iti__tz {
  height: 14px;
  background-position: -5153px 0px;
}
.iti__flag.iti__ua {
  height: 14px;
  background-position: -5175px 0px;
}
.iti__flag.iti__ug {
  height: 14px;
  background-position: -5197px 0px;
}
.iti__flag.iti__um {
  height: 11px;
  background-position: -5219px 0px;
}
.iti__flag.iti__un {
  height: 14px;
  background-position: -5241px 0px;
}
.iti__flag.iti__us {
  height: 11px;
  background-position: -5263px 0px;
}
.iti__flag.iti__uy {
  height: 14px;
  background-position: -5285px 0px;
}
.iti__flag.iti__uz {
  height: 10px;
  background-position: -5307px 0px;
}
.iti__flag.iti__va {
  height: 15px;
  background-position: -5329px 0px;
}
.iti__flag.iti__vc {
  height: 14px;
  background-position: -5346px 0px;
}
.iti__flag.iti__ve {
  height: 14px;
  background-position: -5368px 0px;
}
.iti__flag.iti__vg {
  height: 10px;
  background-position: -5390px 0px;
}
.iti__flag.iti__vi {
  height: 14px;
  background-position: -5412px 0px;
}
.iti__flag.iti__vn {
  height: 14px;
  background-position: -5434px 0px;
}
.iti__flag.iti__vu {
  height: 12px;
  background-position: -5456px 0px;
}
.iti__flag.iti__wf {
  height: 14px;
  background-position: -5478px 0px;
}
.iti__flag.iti__ws {
  height: 10px;
  background-position: -5500px 0px;
}
.iti__flag.iti__xk {
  height: 15px;
  background-position: -5522px 0px;
}
.iti__flag.iti__ye {
  height: 14px;
  background-position: -5544px 0px;
}
.iti__flag.iti__yt {
  height: 14px;
  background-position: -5566px 0px;
}
.iti__flag.iti__za {
  height: 14px;
  background-position: -5588px 0px;
}
.iti__flag.iti__zm {
  height: 14px;
  background-position: -5610px 0px;
}
.iti__flag.iti__zw {
  height: 10px;
  background-position: -5632px 0px;
}

.iti {
  position: relative;
  display: inline-block;
}
.iti * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti input, .iti input[type=text], .iti input[type=tel] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}
.iti__flag-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 1px;
}
.iti__selected-flag {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
}
.iti__arrow {
  margin-left: 6px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid #555;
}
.iti__country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  border: 1px solid #CCC;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti__country-list--dropup {
  bottom: 100%;
  margin-bottom: -1px;
}
@media (max-width: 500px) {
  .iti__country-list {
    white-space: normal;
  }
}
.iti__flag-box {
  display: inline-block;
  width: 20px;
}
.iti__divider {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CCC;
}
.iti__country {
  padding: 5px 10px;
  outline: none;
}
.iti__dial-code {
  color: #999;
}
.iti__country.iti__highlight {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti__flag-box, .iti__country-name, .iti__dial-code {
  vertical-align: middle;
}
.iti__flag-box, .iti__country-name {
  margin-right: 6px;
}
.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
}
.iti--allow-dropdown .iti__flag-container, .iti--separate-dial-code .iti__flag-container {
  right: auto;
  left: 0;
}
.iti--allow-dropdown .iti__flag-container:hover {
  cursor: pointer;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
  cursor: default;
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover .iti__selected-flag,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover .iti__selected-flag {
  background-color: transparent;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0.05);
}
.iti--separate-dial-code .iti__selected-dial-code {
  margin-left: 6px;
}
.iti--container {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: 1px;
}
.iti--container:hover {
  cursor: pointer;
}

.iti-mobile .iti--container {
  top: 30px;
  bottom: 30px;
  left: 30px;
  right: 30px;
  position: fixed;
}
.iti-mobile .iti__country-list {
  max-height: 100%;
  width: 100%;
}
.iti-mobile .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../img/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../img/flags@2x.png");
  }
}

.iti__flag.iti__np {
  background-color: transparent;
}

.management {
  margin-top: 55px;
  padding-bottom: 50px;
}

.management__wrapper {
  display: grid;
  margin: 55px auto 0 auto;
}
.management__wrapper.management__wrapper--v1 {
  max-width: 1485px;
  grid-template-columns: repeat(5, 1fr);
  gap: 45px;
}
.management__wrapper.management__wrapper--v1 .management-item__img {
  overflow: hidden;
  height: 178px;
  border-radius: 16px;
}
.management__wrapper.management__wrapper--v1 .management-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.management__wrapper.management__wrapper--v1 .management-item {
  padding: 8px;
}
.management__wrapper.management__wrapper--v1 .management-item__name {
  margin-top: 7px;
}
.management__wrapper.management__wrapper--v1 .management-item__info {
  border-radius: 0px 0px 16px 16px;
  padding: 20px;
  margin-top: 13px;
}
.management__wrapper.management__wrapper--v1 .reception-days-block {
  margin-top: 32px;
}
.management__wrapper.management__wrapper--v2 {
  max-width: 1196px;
  grid-template-columns: 1fr;
  gap: 23px;
}
.management__wrapper.management__wrapper--v2 .management-item__img {
  overflow: hidden;
  height: 225px;
  border-radius: 16px;
  width: 260px;
}
.management__wrapper.management__wrapper--v2 .management-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.management__wrapper.management__wrapper--v2 .management-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  column-gap: 40px;
  overflow: hidden;
}
.management__wrapper.management__wrapper--v2 .management-item__info {
  width: calc(100% - 590px);
  height: 100%;
  display: flex;
  padding-top: 80px;
  position: relative;
  z-index: 2;
}
.management__wrapper.management__wrapper--v2 .management-item__info::before {
  position: absolute;
  content: "";
  background: #F2F2F2;
  width: 110%;
  height: 115%;
  left: 0;
  top: -12px;
  z-index: -1;
}
.management__wrapper.management__wrapper--v2 .management-item__btn {
  width: 244px;
}
.management__wrapper.management__wrapper--v2 .contact-block {
  height: fit-content;
  padding-right: 35px;
  border-right: 1px solid #000000;
  margin-right: 40px;
  padding-left: 40px;
}
.management__wrapper.management__wrapper--v2 .contact-block__title {
  text-align: left;
  color: #000000;
  font-weight: 700;
}
.management__wrapper.management__wrapper--v2 .contact-block a {
  padding-left: 0;
  text-align: left;
}
.management__wrapper.management__wrapper--v2 .reception-days-block__title {
  text-align: left;
  color: #000000;
  font-weight: 700;
}
.management__wrapper.management__wrapper--v2 .reception-days-block div {
  text-align: left;
}

.management-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
}

.management-item__name {
  font-weight: 700;
  font-size: 20px;
  line-height: 118.19%;
  text-align: center;
  color: #292929;
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;
}

.management-item__descr {
  font-weight: 400;
  font-size: 16px;
  line-height: 118.19%;
  text-align: center;
  color: #070707;
  margin-top: 8px;
  height: 38px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;
}

.management-item__info {
  background: #F2F2F2;
}

.contact-block .svg {
  width: 14px;
  height: 14px;
  margin-right: 7px;
}
.contact-block a {
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 76.19%;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 7px;
  text-align: center;
}

.contact-block__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 76.19%;
  color: #1149D6;
  text-align: center;
  margin-bottom: 11px;
  display: block;
}

.reception-days-block {
  height: 55px;
}
.reception-days-block div {
  font-weight: 400;
  font-size: 16px;
  line-height: 76.19%;
  text-transform: capitalize;
  color: #1D1E1F;
  text-align: center;
  margin-bottom: 9px;
}
.reception-days-block div:last-child {
  margin-bottom: 0;
}
.reception-days-block div span {
  color: #1149D6;
  font-weight: 700;
}

.reception-days-block__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 76.19%;
  color: #1149D6;
  text-align: center;
  margin-bottom: 11px;
  display: block;
}

.management-item__btn {
  width: 100%;
  background: #002EA3;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  height: 40px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  transition: all 0.3s ease-in-out;
}
.management-item__btn:hover {
  background: #00237c;
}

.modal-wrap-management {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-wrap-management.active {
  opacity: 1;
  visibility: visible;
}

.modal-management {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
  height: fit-content;
  min-width: 849px;
  max-width: 849px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 95vh;
}
.modal-management::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.modal-management__body {
  background: #FFFFFF;
  box-shadow: 5px 10px 23px rgba(13, 44, 127, 0.53);
  border-radius: 15px;
  padding: 40px 50px;
  font-weight: 400;
  font-size: 20px;
  line-height: 143%;
  color: #222222;
}

.modal-management__close {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
}
.modal-management__close svg {
  width: 100%;
  height: 100%;
}

.organizations {
  margin-top: 55px;
}

.organizations__wrapper {
  margin: 65px auto 0 auto;
  max-width: 1196px;
  row-gap: 30px;
  display: flex;
  flex-direction: column;
}

.organizations-item {
  background: #FFFFFF;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  border-radius: 16px;
  display: flex;
  padding: 10px;
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.organizations-item::before {
  position: absolute;
  content: "";
  background: #F2F2F2;
  height: 100px;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.organizations-item__img {
  width: 259px;
  height: 226px;
  margin-right: 25px;
  border-radius: 13px;
  overflow: hidden;
}
.organizations-item__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.organizations-item__center .contact-block {
  margin-top: 37px;
}
.organizations-item__center .contact-block__title {
  text-align: left;
  font-weight: 700;
  margin-bottom: 15px;
}
.organizations-item__center .contact-block__wrapper {
  display: flex;
  column-gap: 20px;
}
.organizations-item__center .site {
  color: #002EA3;
}
.organizations-item__center .contact-block a {
  padding-left: 0;
  display: flex;
  align-items: center;
  line-height: 110%;
}
.organizations-item__center .location {
  align-items: flex-start !important;
  line-height: 154.19% !important;
  margin-top: -5px;
}
.organizations-item__center .location svg {
  margin-top: 6px;
}

.organizations-item__top {
  font-weight: 700;
  font-size: 20px;
  line-height: 118.19%;
  color: #002EA3;
  padding-top: 5px;
}

.organizations-item__name {
  margin-top: 30px;
  font-weight: 700;
  font-size: 20px;
  line-height: 118.19%;
  color: #292929;
}
.organizations-item__name span {
  font-weight: 400;
  font-size: 16px;
  line-height: 118.19%;
  color: #070707;
  display: block;
  margin-bottom: 8px;
}

.organizations-item__right {
  width: 298px;
  margin-left: auto;
  background: #002EA3;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.organizations-item__right::before {
  position: absolute;
  content: "";
  width: 110%;
  height: 115%;
  left: 0;
  top: -10px;
  z-index: -1;
  background: #002EA3;
}
.organizations-item__right .reception-days-block__title {
  color: #FFFFFF;
  margin-bottom: 15px;
  font-weight: 700;
}
.organizations-item__right .reception-days-block div {
  color: #FFFFFF;
}
.organizations-item__right .reception-days-block div span {
  color: #FFFFFF;
}

.divisions {
  margin-top: 45px;
  padding-bottom: 50px;
}

.divisions__wrapper {
  margin: 40px auto 0 auto;
  display: flex;
  column-gap: 15px;
  max-width: 1780px;
}
.divisions__wrapper .tab-link-wrapper {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
  border-radius: 5px;
  width: 340px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: fit-content;
}
.divisions__wrapper .tab {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  width: 100%;
  height: 64px;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  color: #111111;
  transition: all 0.3s ease-in-out;
}
.divisions__wrapper .tab:hover {
  background: #ececec;
}
.divisions__wrapper .tab-active {
  background: #002EA3;
  color: #FFFFFF;
  border: 1px solid #002EA3;
}
.divisions__wrapper .tab-active:hover {
  background: #002EA3;
}
.divisions__wrapper .tab-content-wrapper {
  width: calc(100% - 355px);
}
.divisions__wrapper .tabs-content {
  display: none;
}
.divisions__wrapper .tabs-content a {
  display: block;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0px;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #1149D6;
  display: flex;
  align-items: center;
  padding: 20px 25px;
  transition: all 0.3s ease-in-out;
}
.divisions__wrapper .tabs-content a:hover {
  background: #ececec;
}
.divisions__wrapper .tabs-content-active {
  display: block;
}

.map-sec {
  margin-top: 60px;
}

.map-sec__wrapper {
  margin-top: 50px;
}

.map-sec-block {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
  border-radius: 5px;
  width: 100%;
  padding: 60px 80px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}
.map-sec-block .contact-bottom {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 40px;
  row-gap: 23px;
}
.map-sec-block .contact__right {
  width: 790px;
  padding-top: 10px;
}
.map-sec-block .contact__right iframe {
  height: 424px;
  border-radius: 44px;
}

.map-sec-block__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #000000;
  padding-bottom: 18px;
  position: relative;
}
.map-sec-block__title::before {
  position: absolute;
  content: "";
  background-color: #002EA3;
  width: 293px;
  height: 7px;
  left: 0;
  bottom: 0;
}

.gallery {
  margin-top: 30px;
}
.gallery .container {
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
  border-radius: 5px;
  max-width: 1438px;
  padding: 35px 50px 70px 50px;
}
.gallery .container .swiper {
  max-width: 1148px;
  margin: 0 auto;
}
.gallery .container .swiper-slide {
  border-radius: 15px;
  overflow: hidden;
}
.gallery .container .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery .container .swiper-button-next,
.gallery .container .swiper-button-prev {
  width: 62px;
  height: 62px;
  transition: all 0.3s ease-in-out;
}
.gallery .container .swiper-button-next:hover,
.gallery .container .swiper-button-prev:hover {
  opacity: 0.6;
}
.gallery .container .swiper-button-next::after,
.gallery .container .swiper-button-prev::after {
  font-size: 0;
}
.gallery .container .swiper-button-prev {
  content: url("../img/arrow-prev.svg");
}
.gallery .container .swiper-button-next {
  content: url("../img/arrow-next.svg");
}

.gallery__wrapper {
  position: relative;
  margin-top: 40px;
}

.legal-acts-top {
  height: 420px;
  position: relative;
  z-index: 2;
  padding: 88px 0 195px 0;
}
.legal-acts-top .legal-acts-top-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}
.legal-acts-top .container {
  max-width: 815px;
}

.legal-acts-top__title {
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 19px;
}

.legal-acts-top__descr {
  font-weight: 400;
  font-size: 25px;
  line-height: 100.19%;
  text-align: center;
  margin-top: 20px;
  color: #FFFFFF;
}

.legal-acts-accardion {
  width: 100%;
  margin-top: -130px;
  z-index: 5;
}
.legal-acts-accardion .accardion {
  max-width: 100%;
}
.legal-acts-accardion .accardion__item {
  border: none;
  border-radius: 0;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 0;
}
.legal-acts-accardion .accardion__item:first-child .accardion__item--title {
  border-radius: 10px 10px 0px 0px;
}
.legal-acts-accardion .accardion__item--title {
  background: #FFFFFF;
  border: 1px solid #F9F7F7;
}
.legal-acts-accardion .accardion__item--descr {
  padding: 0;
}
.legal-acts-accardion .accardion__item--title::after {
  background-image: url("../img/arrow-acc-two.svg");
  width: 16px;
  height: 7px;
}

.legal-acts-item {
  background: #F4F4F4;
  border: 1px solid #E6E6E6;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
}
.legal-acts-item:last-child {
  border-bottom: none;
}

.legal-acts-item--done {
  background: #DFE2E6;
}
.legal-acts-item--done .legal-acts-item__btn {
  background: #002EA3;
  border: 1px solid #002EA3;
  color: #fff;
  pointer-events: none;
}

.legal-acts-item__top {
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
}

.legal-acts-item__title {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #404040;
  align-items: center;
  display: flex;
  column-gap: 18px;
}

.legal-acts-item__btn {
  border: 1px solid #656565;
  border-radius: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #464646;
  padding: 10px 20px;
  transition: all 0.3s ease-in-out;
}
.legal-acts-item__btn:hover {
  background-color: #fff;
}

.status {
  background: #FFFFFF;
  border-radius: 6.5px;
  max-width: 1157px;
  height: 13px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden;
}
.status.status-show {
  display: block;
}
.status.status-hide {
  display: none;
}
.status span {
  background: #AFB1B7;
  border-radius: 6.5px;
  height: 100%;
  display: block;
}

.directory {
  margin-top: 40px;
  padding-bottom: 30px;
}

.directory__wrapper {
  margin: 70px auto 0 auto;
  max-width: 1178px;
}

.directory-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
}
.directory-item:first-child {
  border-radius: 16px 16px 0 0;
}

.directory-item__left {
  font-weight: 700;
  font-size: 20px;
  line-height: 118.19%;
  color: #292929;
  padding: 30px 35px;
  display: flex;
  align-items: center;
}

.directory-item__right {
  padding: 30px;
  background: #F2F2F2;
}
.directory-item__right a {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 118.19%;
  color: #212121;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
}
.directory-item__right a:hover {
  text-shadow: 0.5px 0 0 currentColor;
}
.directory-item__right a:last-child {
  margin-bottom: 0;
}
.directory-item__right .svg {
  width: 19px;
  height: 19px;
}

.latest-news {
  margin-top: 100px;
  background: #F8F8F8;
  padding: 55px 0 70px 0;
}

.latest-news__wrapper {
  display: grid;
  grid-template-columns: calc(70% - 20px) calc(30% - 20px);
  justify-content: space-between;
}

.latest-news-left .news-sec-item {
  overflow: hidden;
  padding: 26px;
}
.latest-news-left .news-sec-item__img {
  height: 221px;
  width: 130%;
  transform: translateX(-27px);
  margin-top: -26px;
}
.latest-news-left .news-sec-item__title {
  height: 23px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 19px;
  line-height: 25px;
  text-transform: none;
  text-align: left;
  margin-top: 26px;
}
.latest-news-left .news-sec-item__descr {
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  margin-top: 15px;
}
.latest-news-left .news-sec-item__bottom {
  margin-top: 20px;
}

.latest-news-left__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  column-gap: 30px;
}
.latest-news-left__top::before {
  position: absolute;
  content: "";
  width: calc(100% - 165px);
  height: 1px;
  background-color: #E1E1E1;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.latest-news__title {
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  letter-spacing: 0.015em;
  color: #1B1B1B;
  background: #F8F8F8;
  padding-right: 15px;
}

.latest-news__link {
  background: #EEEEEE;
  border-radius: 4px;
  padding: 16px 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1B1B1B;
}

.latest-news-left__wrapper {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.latest-news-right .latest-news__title {
  text-align: center;
}

.latest-news-right__wrapper {
  margin-top: 53px;
  background: #EEEEEE;
  border-radius: 10px;
  padding: 25px;
  height: calc(100% - 90px);
}

.latest-news-item {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 25px;
  transition: all 0.3s ease-in-out;
  display: block;
}
.latest-news-item:hover {
  box-shadow: 0px 19px 18px rgba(17, 73, 214, 0.13);
}

.latest-news-item__descr {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #1B1B1B;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  overflow-x: hidden;
}

.latest-news-item__bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.latest-news-right__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #1B1B1B;
  display: block;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 1590px) {
  .news-sec__wrapper {
    gap: 20px;
  }

  .news-sec-item__btn {
    width: 125px;
  }

  .news-sec__top {
    gap: 20px;
    grid-template-columns: 20% 49% 28%;
  }
}
@media (max-width: 1500px) {
  .useful-links__block-v1 {
    gap: 20px;
  }

  .useful-links .tab-content-wrapper {
    margin-top: 40px;
  }

  .useful-links {
    margin-top: 60px;
  }

  .useful-links .tab {
    padding: 20px;
  }

  .useful-links__block-v2 {
    gap: 20px;
  }

  .latest-news {
    margin-top: 60px;
  }

  .management__wrapper.management__wrapper--v1 {
    gap: 20px;
  }
}
@media (max-width: 1450px) {
  .promo-block__link-wrap a {
    font-size: 12px;
  }

  .promo-left p {
    font-size: 28px;
    line-height: 36px;
  }

  .promo-left img {
    height: 120px;
  }

  .promo {
    padding: 40px 0;
  }

  .promo-block__title {
    font-size: 18px !important;
  }

  .promo-block__subtitle {
    font-size: 10px;
  }

  .promo__block {
    padding: 15px;
  }

  .footer-top {
    flex-wrap: wrap;
  }

  .footer-top-list {
    order: 3;
  }
  .footer-top-list li {
    text-align: center;
  }

  .footer-top-list {
    grid-template-columns: repeat(3, 310px);
    max-width: 100%;
  }

  .footer-social {
    margin-left: 0;
  }

  .footer-right {
    max-width: 100%;
    justify-content: space-between;
  }
}
@media (max-width: 1400px) {
  .management__wrapper.management__wrapper--v1 {
    grid-template-columns: repeat(4, 1fr);
  }

  .news-sec__wrapper {
    grid-template-columns: repeat(4, 1fr);
  }

  .management {
    padding-bottom: 0;
  }

  .map-sec-block {
    padding: 40px;
    column-gap: 20px;
  }

  .map-sec-block__title {
    font-size: 32px;
    line-height: 38px;
  }

  .news-sec__top {
    grid-template-columns: 25% 46% 26%;
  }

  .news-sec .news-sec__wrapper {
    margin-top: 20px;
  }

  .gallery .container .swiper {
    max-width: 100%;
  }

  .gallery .container .swiper-button-next,
.gallery .container .swiper-button-prev {
    filter: invert(100%);
  }

  .gallery .container {
    padding: 30px;
  }

  .gallery__wrapper {
    margin-top: 20px;
  }
}
@media (max-width: 1350px) {
  .useful-links__block-v1 {
    grid-template-columns: repeat(3, 1fr);
  }

  .useful-links__block-v1 .useful-links-item {
    padding: 30px 20px;
  }

  .latest-news-left__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .latest-news-right__wrapper {
    height: fit-content;
  }

  .latest-news__title {
    font-size: 28px;
  }

  .latest-news__wrapper {
    grid-template-columns: calc(60% - 20px) calc(40% - 20px);
  }

  .contact__wrapper {
    column-gap: 40px;
  }
}
@media (max-width: 1300px) {
  .open-data-chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 50px;
    margin-top: 40px;
  }
  .open-data-chart .my-chart {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .open-data-chart .open-data-chart__column--mob {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .open-data-chart__column--mob {
    order: 2;
  }

  .open-data-chart__item--bottom {
    margin: 0;
    margin-bottom: 55px;
  }

  .open-data-chart .my-chart {
    margin: 0 auto;
    margin-bottom: 40px;
  }

  .open-data-chart__item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .open-data-chart__column {
    max-width: 100%;
  }

  .open-data {
    padding: 40px 0;
  }

  .open-data::before {
    display: none;
  }

  .more-statistic-block {
    margin-bottom: 50px;
  }

  .more-statistic-block-v2 .more-statistic-item__num {
    font-size: 34px;
  }

  .more-statistic-block-v2 .more-statistic-item__title {
    font-size: 16px;
    height: 59px;
  }

  .more-statistic-block-v2 .more-statistic-item {
    padding: 25px;
    width: 200px;
    height: 260px;
  }

  .more-statistic-block__title {
    font-size: 32px;
    line-height: 40px;
  }

  .more-statistic-block__subtitle {
    font-size: 22px;
  }

  .more-statistic-block__num {
    font-size: 28px;
  }

  .more-statistic-block__wrapper {
    margin-top: 40px;
  }

  .more-statistic-item {
    padding: 25px;
    width: 200px;
    height: auto;
    row-gap: 10px;
  }

  .more-statistic-item__num {
    font-size: 34px;
  }

  .more-statistic-item__icon {
    height: 75px;
  }

  .more-statistic-item__title {
    height: 55px;
  }

  .contact-page__wrapper .contact__left {
    max-width: 600px;
  }

  .news-sec-item--big {
    padding: 20px;
  }

  .news-sec-item--big .news-sec-item__title {
    font-size: 26px;
    line-height: 29px;
    margin-top: 20px;
  }

  .news-sec-item--big .news-sec-item__descr {
    font-size: 17px;
    margin-top: 30px;
  }
}
@media (max-width: 1200px) {
  .burger {
    display: block;
    margin-right: 0;
    z-index: 300;
  }

  .header {
    z-index: 205;
  }

  .nav {
    position: fixed;
    z-index: 200;
    width: calc(100% - 60px);
    height: 100%;
    left: 0;
    top: 0;
    padding: 40px 0;
    transform: translateX(-150%);
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
  }
  .nav.active {
    transform: translateX(0);
  }

  .header-top-left {
    display: none;
  }

  .header-phone {
    margin-right: 10px;
  }
  .header-phone span {
    display: none;
  }
  .header-phone a {
    font-size: 0;
  }

  .header-phone a::before {
    width: 15px;
    height: 15px;
  }

  .header-top-right {
    margin-left: auto;
    margin-right: 20px;
  }

  .nav__list {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 20px;
  }

  .nav__item {
    width: 100%;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
  }

  .nav__link {
    width: 100%;
    display: block;
    text-align: left;
  }

  .header-social {
    margin-left: 15px;
  }
  .header-social img {
    filter: invert(100%);
    mix-blend-mode: luminosity;
  }

  .title {
    font-size: 32px;
  }

  .useful-links .tab-link-wrapper {
    margin-top: 30px;
  }

  .useful-links {
    margin-top: 40px;
  }

  .contact-bottom-item a {
    font-size: 18px;
  }

  .contact-bottom-item__title {
    font-size: 26px;
    line-height: 25px;
  }

  .contact-bottom-item__icon {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }
  .contact-bottom-item__icon img {
    transform: scale(0.7);
  }

  .contact-bottom {
    justify-content: space-between;
  }

  .nav-two {
    background-color: transparent;
    margin-bottom: 20px;
  }

  .nav-two__list {
    flex-direction: column;
    row-gap: 10px;
  }

  .nav-two__link {
    color: #fff;
    text-align: left;
  }

  .nav-two__item {
    width: 100%;
  }

  .nav-two__link.active {
    padding: 0;
  }

  .online-services-page .container {
    padding: 40px 15px;
  }

  .accardion__item--title {
    font-size: 20px;
    padding: 15px 30px;
    padding-right: 70px;
  }

  .accardion__item--descr {
    font-size: 18px;
  }

  .online-services-page__title {
    margin-bottom: 40px;
  }

  .directory__wrapper {
    margin-top: 40px;
  }
}
@media (max-width: 1150px) {
  .management__wrapper.management__wrapper--v1 {
    grid-template-columns: repeat(3, 1fr);
  }

  .news-sec__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .management__wrapper.management__wrapper--v2 .management-item__info {
    flex-direction: column;
    padding-left: 25px;
    row-gap: 15px;
    padding-top: 41px;
  }

  .management__wrapper.management__wrapper--v2 .contact-block {
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }

  .organizations__wrapper {
    margin: 30px auto 0 auto;
  }

  .organizations {
    margin-top: 40px;
  }

  .organizations-item__right {
    width: 220px;
  }
}
@media (max-width: 1050px) {
  .organizations-item__center .contact-block__wrapper {
    flex-wrap: wrap;
    row-gap: 10px;
  }

  .organizations-item__center .contact-block a {
    text-align: left;
  }

  .news-sec__top {
    grid-template-columns: repeat(2, 1fr);
  }

  .news-sec-filter {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 20px;
  }
}
@media (max-width: 1020px) {
  .promo__wrapper {
    flex-direction: column;
    row-gap: 30px;
  }

  .promo-left {
    max-width: 100%;
    width: 100%;
  }

  .promo__block {
    width: 100%;
  }

  .promo-block__link-wrap {
    max-width: 700px;
    margin: 15px auto 0 auto;
  }

  .promo-block__img {
    text-align: center;
  }

  .promo-left p {
    font-size: 24px;
    line-height: 31px;
  }

  .promo-left img {
    height: 100px;
  }

  .useful-links__block-v2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .latest-news__wrapper {
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  }

  .latest-news-left__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .contact-bottom-item {
    flex-direction: column;
    row-gap: 15px;
  }

  .contact-bottom-item__right {
    text-align: center;
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  .contact-bottom {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-around;
    margin-top: 50px;
    align-items: flex-start;
  }

  .footer-top-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .footer-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
  }

  .footer-right p {
    text-align: center;
    width: 100%;
  }

  .footer-right p a {
    display: block;
  }

  .footer__logo {
    text-align: center;
  }

  .footer-social {
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .management__wrapper.management__wrapper--v2 .management-item__info {
    width: calc(100% - 530px);
  }

  .contact-page__wrapper .container {
    flex-direction: column;
  }

  .contact-page__wrapper .contact__left {
    max-width: 100%;
  }

  .contact-page__wrapper {
    margin-top: 40px;
  }
  .contact-page__wrapper .input-block input {
    height: 50px;
  }
  .contact-page__wrapper .textarea-block textarea {
    height: 250px;
  }
  .contact-page__wrapper .contact__btn {
    height: 50px;
    margin-top: 30px;
  }

  .contact-page__wrapper .contact-bottom {
    margin-top: 50px;
    max-height: inherit;
    row-gap: 40px;
  }

  .contact__right {
    padding-top: 40px;
  }

  .contact-page .contact__right iframe {
    height: 350px;
  }

  .contact-page__top {
    height: 150px;
  }

  .contact-page__top .container {
    font-size: 28px;
  }

  .map-sec-block {
    flex-direction: column;
    padding: 20px;
  }

  .map-sec-block .contact__right {
    width: 100%;
    margin-top: 20px;
  }

  .map-sec-block .contact__right iframe {
    height: 300px;
    margin-top: 30px;
  }

  .map-sec-block__title {
    font-size: 28px;
    line-height: 34px;
  }

  .map-sec__wrapper {
    margin-top: 40px;
  }

  .gallery .container .swiper-button-next,
.gallery .container .swiper-button-prev {
    width: 40px;
    height: 40px;
  }

  .legal-acts-top__title {
    font-size: 32px;
    line-height: 38px;
  }

  .legal-acts-top__descr {
    font-size: 22px;
    margin-top: 15px;
  }

  .legal-acts-top {
    padding: 53px 0 160px 0;
    height: auto;
  }

  .legal-acts-item__title {
    font-size: 18px;
  }

  .legal-acts-item__btn {
    padding: 7px 10px;
    white-space: nowrap;
  }
}
@media (max-width: 950px) {
  .latest-news__wrapper {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }

  .latest-news-left__wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
  }

  .latest-news {
    padding-top: 40px;
    margin-top: 40px;
  }

  .latest-news-right__wrapper {
    margin-top: 30px;
  }

  .partners__wrapper {
    margin-top: 40px;
  }

  .partners {
    padding: 30px 0;
  }

  .organizations-item {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .organizations-item__right {
    width: 100%;
    padding: 20px;
  }

  .organizations-item__right::before {
    left: -12px;
    top: -3px;
  }

  .organizations-item__center {
    max-width: 490px;
  }

  .legal-acts-item {
    padding: 15px;
  }

  .legal-acts-item__title {
    font-size: 16px;
    column-gap: 10px;
  }

  .legal-acts-item__btn {
    font-size: 14px;
    height: fit-content;
  }

  .legal-acts-item__top {
    align-items: center;
  }
}
@media (max-width: 850px) {
  .contact {
    padding: 40px 0;
    margin-top: 20px;
  }

  .contact__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .contact__wrapper .contact__right {
    padding-top: 40px;
  }
  .contact__wrapper .contact__right iframe {
    margin-top: 25px;
    height: 500px;
  }
  .contact__wrapper .input-block input {
    height: 50px;
  }
  .contact__wrapper .textarea-block textarea {
    height: 215px;
  }
  .contact__wrapper .contact__title {
    font-size: 28px;
  }
  .contact__wrapper .contact__subtitle {
    font-size: 18px;
  }
  .contact__wrapper .contact__btn {
    margin-top: 40px;
    height: 50px;
  }
  .contact__wrapper .contact__right span {
    font-size: 22px;
  }

  .form-sec__wrapper {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 20px;
    row-gap: 20px;
  }

  .form-sec {
    margin-top: 40px;
  }

  .mt-47 {
    margin-top: 24px;
  }

  .management__wrapper.management__wrapper--v1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .news-sec__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }

  .management__wrapper.management__wrapper--v2 .management-item {
    flex-wrap: wrap;
    row-gap: 25px;
    justify-content: space-around;
  }

  .management__wrapper.management__wrapper--v2 .management-item__info {
    height: auto;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
    padding-top: 25px;
  }

  .management__wrapper.management__wrapper--v2 .management-item__info::before {
    left: -14px;
    top: -5px;
  }

  .divisions__wrapper {
    flex-direction: column;
  }

  .divisions__wrapper .tab-link-wrapper {
    width: 100%;
  }

  .divisions__wrapper .tab-content-wrapper {
    width: 100%;
    margin-top: 10px;
  }

  .divisions__wrapper .tab {
    height: 50px;
  }

  .divisions {
    padding-bottom: 0;
  }

  .modal-management {
    min-width: 90%;
    max-width: 90%;
  }

  .modal-management__body {
    padding: 50px 20px 20px 20px;
    font-size: 18px;
  }
}
@media (max-width: 760px) {
  .useful-links__block-v1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .useful-links .tab-link-wrapper {
    flex-direction: column;
  }

  .useful-links .tab {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }

  .useful-links__block-v2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .useful-links__block-v2 .useful-links-item {
    min-height: 60px;
    font-size: 16px;
    padding: 15px 20px;
  }
  .useful-links__block-v2 .useful-links-item br {
    display: none;
  }

  .open-data-chart {
    grid-template-columns: repeat(1, 1fr);
  }

  .open-data-chart .my-chart {
    grid-column-start: 1;
    grid-column-end: 2;
    max-width: 340px;
  }

  .open-data-chart__item--num {
    font-size: 24px;
  }

  .open-data-chart__item {
    height: auto;
  }

  .more-statistic-block__title {
    font-size: 28px;
    line-height: 32px;
  }

  .contact-bottom-item a br {
    display: none;
  }

  .form-sec__bottom {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }

  .accardion__item--descr {
    padding-right: 30px;
  }

  .organizations__wrapper {
    row-gap: 20px;
  }

  .directory-item__left {
    font-size: 18px;
    padding: 20px;
  }

  .legal-acts-top__title {
    text-align: left;
  }
}
@media (max-width: 650px) {
  .latest-news-left__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .directory-item {
    grid-template-columns: repeat(1, 1fr);
  }

  .directory {
    padding-bottom: 0;
  }

  .news-sec-item--big .news-sec-item__title {
    font-size: 24px;
  }

  .news-sec-item--big .news-sec-item__descr {
    font-size: 16px;
    margin-top: 25px;
  }

  .news-sec-item__share {
    font-size: 16px;
  }

  .news-sec-item__view {
    font-size: 16px;
  }

  .news-sec-item--big .news-sec-item__date {
    font-size: 16px;
  }

  .legal-acts-top__title {
    flex-direction: column;
    text-align: center;
    row-gap: 20px;
    font-size: 26px;
    line-height: 30px;
  }

  .legal-acts-top__descr {
    font-size: 18px;
    line-height: 120%;
  }

  .legal-acts-accardion {
    margin-top: -58px;
  }

  .legal-acts-top {
    padding: 40px 15px 90px 15px;
  }
}
@media (max-width: 550px) {
  .promo-block__link-wrap {
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-around;
  }

  .promo-left {
    flex-direction: column;
    text-align: center;
    row-gap: 10px;
    padding: 15px;
    background: linear-gradient(360deg, rgba(0, 46, 163, 0.35) 6.39%, rgba(0, 46, 163, 0.808732) 44.23%, rgba(0, 46, 163, 0.03) 99.54%);
  }

  .promo-left p {
    font-size: 18px;
    line-height: 27px;
  }

  .latest-news-right__wrapper {
    padding: 0;
    background-color: transparent;
  }

  .latest-news-left .news-sec-item {
    padding: 20px;
  }

  .latest-news-left .news-sec-item__title {
    font-size: 16px;
    margin-top: 15px;
  }

  .latest-news-left .news-sec-item__descr {
    font-size: 16px;
  }

  .latest-news-left .news-sec-item__img {
    height: 180px;
  }

  .latest-news__title {
    font-size: 24px;
  }

  .latest-news-left__top::before {
    display: none;
  }

  .latest-news-left__top {
    flex-direction: column;
    row-gap: 15px;
  }

  .title {
    font-size: 28px;
    line-height: 32px;
  }

  .contact__wrapper .contact__right iframe {
    height: 300px;
  }

  .open-data__btn {
    padding: 15px 40px;
    font-size: 18px;
  }

  .contact-bottom-item a {
    font-size: 16px;
    line-height: 21px;
  }

  .contact-bottom-item__title {
    font-size: 22px;
    line-height: 16px;
  }

  .footer-right {
    grid-template-columns: repeat(1, 1fr);
  }

  .footer-top-list {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }

  .footer-top {
    padding: 30px 15px 30px 15px;
  }

  .footer__logo img {
    height: 120px;
  }

  .management__wrapper.management__wrapper--v1 {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 30px;
  }

  .subtitle {
    font-size: 18px;
  }

  .management {
    margin-top: 40px;
  }

  .news-sec__wrapper {
    grid-template-columns: repeat(1, 1fr);
  }

  .management__wrapper.management__wrapper--v2 {
    margin-top: 30px;
  }

  .map-sec-block__title {
    font-size: 24px;
    line-height: 26px;
  }

  .contact__right span {
    font-size: 22px;
  }

  .map-sec {
    margin-top: 40px;
  }

  .news-sec__top {
    grid-template-columns: 1fr;
  }

  .news-sec-filter {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
@media (max-width: 500px) {
  .contact-bottom {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 30px;
  }

  .partners .swiper-slide img {
    width: 100%;
  }

  .form-sec__block-wrap {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }

  .online-services-page__title {
    text-align: left;
  }
  .online-services-page__title img {
    height: 50px;
  }

  .input-block__wrap {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
  }

  .accardion__item--title {
    font-size: 18px;
    padding: 15px 20px;
    padding-right: 70px;
  }

  .accardion__item--descr {
    font-size: 16px;
    padding: 20px;
    padding-top: 0;
  }

  .management__wrapper.management__wrapper--v2 .management-item__info {
    flex-direction: column;
    text-align: center;
  }

  .management__wrapper.management__wrapper--v2 .contact-block__title {
    text-align: center;
  }

  .management__wrapper.management__wrapper--v2 .contact-block a {
    text-align: center;
  }

  .management__wrapper.management__wrapper--v2 .reception-days-block__title {
    text-align: center;
  }

  .management__wrapper.management__wrapper--v2 .reception-days-block div {
    text-align: center;
  }

  .organizations-item__img {
    width: 100%;
    margin-right: 0;
  }

  .organizations-item__name {
    margin-top: 15px;
  }

  .organizations-item__center .contact-block {
    margin-top: 15px;
  }

  .news-sec-item__view {
    margin-right: 20px;
  }

  .news-sec-item--big .news-sec-item__title {
    font-size: 22px;
    line-height: 25px;
  }

  .gallery .container {
    padding: 20px;
  }

  .modal-management__body {
    font-size: 16px;
  }
}
@media (max-width: 450px) {
  .useful-links__block-v1 {
    grid-template-columns: repeat(1, 1fr);
  }

  .useful-links__block-v1 .useful-links-item__icon {
    margin-top: 40px;
  }

  .open-data-chart .my-chart {
    max-width: 300px;
    margin-bottom: 20px;
  }

  .open-data-chart__item--title {
    font-size: 16px;
  }
}