.useful-links {
    margin-top: 90px;

    .tab-link-wrapper {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .tab {
        background: #EFEFEF;
        border-radius: 10px;
        padding: 30px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #1B1B1B;
    }

    .tab-active {
        background-color: #fff;
    }

    .tab-content-wrapper {
        margin-top: 70px;
    }

    .tabs-content {
        display: none;
    }

}


.useful-links__block-v1 {
    &.tabs-content-active {
        display: grid !important;
    }
    grid-template-columns: repeat(6, minmax(200px, 236px));
    justify-content: center;
    gap: 40px;
    .useful-links-item {
        background: #fff;
        border-radius: 10px;
        padding: 55px 20px 40px;
        transition: all 0.3s ease-in-out;

        &:hover {
            background: #0D48CF;

            .useful-links-item__title {
                color: #fff;
            }

            .useful-links-item__icon {
                -webkit-filter: invert(100%);
                filter: invert(100%);

                &::before {
                    content: url('../img/useful-links-item__icon2.svg');
                }
            }
        }
    }

    .useful-links-item__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #000000;
        transition: all 0.3s ease-in-out;
    }

    .useful-links-item__icon {
        margin-top: 70px;
        display: flex;
        justify-content: center;
        position: relative;
        transition: all 0.3s ease-in-out;

        &::before {
            position: absolute;
            content: url('../img/useful-links-item__icon.svg');
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        img {
            width: 70px;
            height: 70px;
        }
    }
}


.useful-links__block-v2 {
    grid-template-columns: repeat(3, minmax(250px, 390px));
    justify-content: center;
    gap: 40px;
    &.tabs-content-active {
        display: grid !important;
    }
    .useful-links-item {
        background: #fff;
        border-radius: 10px;
        padding: 20px 30px;
        transition: all 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #000000;
        min-height: 80px;
        &:hover {
            background: #0D48CF;
            color: #fff;
        }
    }
}





