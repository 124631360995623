.divisions {
    margin-top: 45px;
    padding-bottom: 50px;
}

.divisions__wrapper {
    margin: 40px auto 0 auto;
    display: flex;
    column-gap: 15px;
    max-width: 1780px;
    .tab-link-wrapper {
        background: #FFFFFF;
        box-shadow: 0px 4px 14px rgba(17, 73, 214, 0.13);
        border-radius: 5px;
        width: 340px;
        padding: 15px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        height: fit-content;
    }
    .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #D6D6D6;
        width: 100%;
        height: 64px;
        transition: all 0.3s ease-in-out;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #111111;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: #ececec;
        }
    }

    .tab-active {
        background: #002EA3;
        color: #FFFFFF;
        border: 1px solid #002EA3;
        &:hover {
            background: #002EA3;
        }
    }

    .tab-content-wrapper {
        width: calc(100% - 355px);
    }

    .tabs-content {
        display: none;
        a {
            display: block;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.08);
            border-radius: 0px;
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #1149D6;
            display: flex;
            align-items: center;
            padding: 20px 25px;
            transition: all 0.3s ease-in-out;
            &:hover {
                background: #ececec;
            }
        }
    }

    .tabs-content-active {
        display: block;
    }
}

