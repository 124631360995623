.contact {
    background: #FFFFFF;
    padding: 70px 0;
    margin-top: 80px;
    .container {
        max-width: 1700px;
    }
}



.contact__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
}


.contact__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    position: relative;
    padding-bottom: 6px;
    &::before {
        position: absolute;
        content: '';
        background: #002EA3;
        width: 185px;
        height: 4px;
        left: 0;
        bottom: 0;
    }
}

.contact__subtitle {
    font-weight: 400;
    font-size: 25px;
    line-height: 122.19%;
    color: #000000;
    margin-top: 20px;

}

.input-block {
    margin-top: 24px;
    input {
        background: #FFFFFF;
        border: 1px solid #8692A6;
        border-radius: 6px;
        width: 100%;
        height: 65px;
        padding: 0 30px;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        color: #494949;
        &:focus {
            border: 1px solid #1565D8;
            box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
        }
    }
}

.title-min {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2B2B2B;
    display: block;
    margin-bottom: 14px;
}

.textarea-block {
    margin-top: 24px;
    textarea {
        background: #FFFFFF;
        border: 1px solid #8692A6;
        border-radius: 6px;
        width: 100%;
        height: 345px;
        padding: 20px 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #494949;
        resize: none;
        &:focus {
            border: 1px solid #1565D8;
            box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
        }
    }
}

.contact__btn {
    width: 100%;
    background: #002EA3;
    border-radius: 6px;
    height: 65px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    margin-top: 55px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #033fd4;
    }
}

.contact__right {
    padding-top: 76px;
    span {
        font-weight: 500;
        font-size: 26px;
        line-height: 107.69%;
        text-align: center;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 15px;
        &::before {
            content: '';
            background-image: url('../img/marker.svg');
            background-repeat: no-repeat;
            background-size: cover;
            width: 25px;
            height: 32px;
            display: block;
        }
    }
    iframe {
        border: 5px solid #002EA3;
        box-sizing: border-box;
        filter: drop-shadow(3px 4px 20px rgba(0, 0, 0, 0.05));
        border-radius: 5px;
        width: 100%;
        height: 715px;
        margin-top: 45px;
    }
}


.contact-bottom {
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: space-around;
   
}

.contact-bottom-item {
    display: flex;
    align-items: center;
    max-width: 500px;
    a {
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-transform: capitalize;
        color: #343434;
        display: block;
    }
}

.contact-bottom-item__icon {
    background: rgba(0, 46, 163, 0.17);
    border: 13px solid rgba(0, 46, 163, 0.17);
    border-radius: 50%;
    width: 107px;
    min-width: 107px;
    height: 107px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-bottom-item__right {
    border-left: 3px solid #002EA3;
    padding-left: 25px;
    margin-left: 25px;
}

.contact-bottom-item__title {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    text-transform: capitalize;
    color: #002EA3;
    margin-bottom: 10px;
}

.contact-page__top {
    position: relative;
    z-index: 2;
    height: 269px;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
    }
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
        color: #FFFFFF;
        height: 100%;
    }
}


.contact-page__title {
    margin-top: 40px;
}

.contact-page {
    .contact__right {
        width: 100%;
        iframe {
            width: 100%;
            height: 471px;
            border: 3px solid #002EA3;
            border-left: none;
            border-right: none;
            border-radius: 0;
        }
    }
}

.contact-page__wrapper {
    margin-top: 100px;
    padding-bottom: 5px;
    .container {
        max-width: 1462px;
        display: flex;
        justify-content: space-between;
    }
    .contact-bottom {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 100px;
        margin-top: 0;
        max-height: 527px;
        margin-top: 154px;
    }
    .contact__left {
        max-width: 737px;
        width: 100%;
    }
}
