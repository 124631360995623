@media (max-width: 1590px) {
    .news-sec__wrapper {
        gap: 20px;
    }
    .news-sec-item__btn {
        width: 125px;
    }
    .news-sec__top {
        gap: 20px;
        grid-template-columns: 20% 49% 28%;
    }
}

@media (max-width: 1500px) {
    .useful-links__block-v1 {
        gap: 20px;
    }
    .useful-links .tab-content-wrapper {
        margin-top: 40px;
    }
    .useful-links {
        margin-top: 60px;
    }
    .useful-links .tab {
        padding: 20px;
    }
    .useful-links__block-v2 {
        gap: 20px;
    }
    .latest-news {
        margin-top: 60px;
    }
    .management__wrapper.management__wrapper--v1 {
        gap: 20px;
    }
}   

@media (max-width: 1450px) {
    .promo-block__link-wrap a {
        font-size: 12px;
    }
    .promo-left p {
        font-size: 28px;
        line-height: 36px;
    }
    .promo-left {
        img {
            height: 120px;
        }
    }
    .promo {
        padding: 40px 0;
    }
    .promo-block__title {
        font-size: 18px !important;
    }
    .promo-block__subtitle {
        font-size: 10px;
    }
    .promo__block {
        padding: 15px;
    }
    .footer-top {
        flex-wrap: wrap;
    }
    .footer-top-list {
        order: 3;
        li {
            text-align: center;
        }
    }
    .footer-top-list {
        grid-template-columns: repeat(3, 310px);
        max-width: 100%;
    }
    .footer-social {
        margin-left: 0;
    }
    .footer-right {
        max-width: 100%;
        justify-content: space-between;
    }
}

@media (max-width: 1400px) {
    .management__wrapper.management__wrapper--v1 {
        grid-template-columns: repeat(4, 1fr);
    }
    .news-sec__wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
    .management {
        padding-bottom: 0;
    }
    .map-sec-block {
        padding: 40px;
        column-gap: 20px;
    }
    .map-sec-block__title {
        font-size: 32px;
        line-height: 38px;
    }
    .news-sec__top {
        grid-template-columns: 25% 46% 26%;
    }
    .news-sec {
        .news-sec__wrapper {
            margin-top: 20px;
        }
    }
    .gallery .container .swiper {
        max-width: 100%;
    }
    .gallery .container .swiper-button-next,
    .gallery .container .swiper-button-prev {
        filter: invert(100%);
    }
    .gallery .container {
        padding: 30px;
    }
    .gallery__wrapper {
        margin-top: 20px;
    }
}

@media (max-width: 1350px) {
    .useful-links__block-v1 {
        grid-template-columns: repeat(3, 1fr);
    }
    .useful-links__block-v1 .useful-links-item {
        padding: 30px 20px;
    }
    .latest-news-left__wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
    .latest-news-right__wrapper {
        height: fit-content;
    }
    .latest-news__title {
        font-size: 28px;
    }
    .latest-news__wrapper {
        grid-template-columns: calc(60% - 20px) calc(40% - 20px);
    }
    .contact__wrapper {
        column-gap: 40px;
    }
}

@media (max-width: 1300px) {
    .open-data-chart {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 50px;
        margin-top: 40px;
        .my-chart {
            grid-column-start: 1;
            grid-column-end: 3;
            
        }
        .open-data-chart__column--mob {
            grid-row-start: 2;
            grid-row-end: 3;
        }
    }
    .open-data-chart__column--mob {
        order: 2;
    }
    .open-data-chart__item--bottom {
        margin: 0;
        margin-bottom: 55px;
    }
    .open-data-chart .my-chart {
        margin: 0 auto;
        margin-bottom: 40px;
    }
    .open-data-chart__item {
        width: 100%;
        max-width: 100%;
        margin-bottom: 20px;
    }
    .open-data-chart__column {
        max-width: 100%;
    }
    .open-data {
        padding: 40px 0;
    }
    .open-data::before {
        display: none;
    }
    .more-statistic-block {
            margin-bottom: 50px;
    }
    .more-statistic-block-v2 .more-statistic-item__num {
        font-size: 34px;
    }
    .more-statistic-block-v2 .more-statistic-item__title {
        font-size: 16px;
        height: 59px;
    }
    .more-statistic-block-v2 .more-statistic-item {
        padding: 25px;
        width: 200px;
        height: 260px;
    }
    .more-statistic-block__title {
        font-size: 32px;
        line-height: 40px;
    }
    .more-statistic-block__subtitle {
        font-size: 22px;
    }
    .more-statistic-block__num {
        font-size: 28px;
    }
    .more-statistic-block__wrapper {
        margin-top: 40px;
    }
    .more-statistic-item {
        padding: 25px;
        width: 200px;
        height: auto;
        row-gap: 10px;
    }
    .more-statistic-item__num {
        font-size: 34px;
    }
    .more-statistic-item__icon {
        height: 75px;
    }
    .more-statistic-item__title {
        height: 55px;
    }
    .contact-page__wrapper .contact__left {
        max-width: 600px;
    }
    .news-sec-item--big {
        padding: 20px;
    }
    .news-sec-item--big .news-sec-item__title {
        font-size: 26px;
        line-height: 29px;
        margin-top: 20px;
    }
    .news-sec-item--big .news-sec-item__descr {
        font-size: 17px;
        margin-top: 30px;
    }

}

@media (max-width: 1200px) {
    .burger {
        display: block;
        margin-right: 0;
        z-index: 300;
    }
    .header {
        z-index: 205;
    }
    .nav {
        position: fixed;
        z-index: 200;
        width: calc(100% - 60px);
        height: 100%;
        left: 0;
        top: 0;
        padding: 40px 0;
        transform: translateX(-150%);
        transition: all 0.3s ease-in-out;
        overflow-y: auto;
        &.active {
            transform: translateX(0);
        }
    }
    .header-top-left {
        display: none;
    }
    .header-phone {
        margin-right: 10px;
        span {
            display: none;
        }
        a {
            font-size: 0;
        }
    }
    .header-phone a::before {
        width: 15px;
        height: 15px;
    }
    .header-top-right {
        margin-left: auto;
        margin-right: 20px;
    }
    .nav__list {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
    }
    .nav__item {
        width: 100%;
        border-bottom: 1px solid #fff;
        padding-bottom: 5px;
    }
    .nav__link {
        width: 100%;
        display: block;
        text-align: left;
    }
    .header-social {
        margin-left: 15px;
        img {
            filter: invert(100%);
            mix-blend-mode: luminosity;
        }
    }
    .title {
        font-size: 32px;
    }
    .useful-links .tab-link-wrapper {
        margin-top: 30px;
    }
    .useful-links {
        margin-top: 40px;
    }
    .contact-bottom-item a {
        font-size: 18px;
    }
    .contact-bottom-item__title {
        font-size: 26px;
        line-height: 25px;
    }
    .contact-bottom-item__icon {
        width: 80px;
        min-width: 80px;
        height: 80px;
        img {
            transform: scale(0.7);
        }
    }
    .contact-bottom {
        justify-content: space-between;
    }
    .nav-two {
        background-color: transparent;
        margin-bottom: 20px;
    }
    .nav-two__list {
        flex-direction: column;
        row-gap: 10px;
    }
    .nav-two__link {
        color: #fff;
        text-align: left;
    }
    .nav-two__item {
        width: 100%;
    }
    .nav-two__link.active {
        padding: 0;
    }
    .online-services-page .container {
        padding: 40px 15px;
    }
    .accardion__item--title {
        font-size: 20px;
        padding: 15px 30px;
        padding-right: 70px;
    }
    .accardion__item--descr {
        font-size: 18px;
    }
    .online-services-page__title {
        margin-bottom: 40px;
    }
    .directory__wrapper {
        margin-top: 40px;
    }
}

@media (max-width: 1150px) {
    .management__wrapper.management__wrapper--v1 {
        grid-template-columns: repeat(3, 1fr);
    }
    .news-sec__wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
    .management__wrapper.management__wrapper--v2 .management-item__info {
        flex-direction: column;
        padding-left: 25px;
        row-gap: 15px;
        padding-top: 41px;
    }
    .management__wrapper.management__wrapper--v2 .contact-block {
        border: none;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
    }
    .organizations__wrapper {
        margin: 30px auto 0 auto;
    }
    .organizations {
        margin-top: 40px;
    }
    .organizations-item__right {
        width: 220px;
    }
}

@media (max-width: 1050px) {
    .organizations-item__center .contact-block__wrapper {
        flex-wrap: wrap;
        row-gap: 10px;
    }
    .organizations-item__center .contact-block a {
        text-align: left;
    }
    .news-sec__top {
        grid-template-columns: repeat(2, 1fr);
    }
    .news-sec-filter {
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 20px;
    }
}

@media (max-width: 1020px) {
    .promo__wrapper {
        flex-direction: column;
        row-gap: 30px;
    }
    .promo-left {
        max-width: 100%;
        width: 100%;
    }
    .promo__block {
        width: 100%;
    }
    .promo-block__link-wrap {
        max-width: 700px;
        margin: 15px auto 0 auto;
    }
    .promo-block__img {
        text-align: center;
    }
    .promo-left p {
        font-size: 24px;
        line-height: 31px;
    }
    .promo-left img {
        height: 100px;
    }
    .useful-links__block-v2 {
        grid-template-columns: repeat(2, 1fr);
    }
    .latest-news__wrapper {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    } 
    .latest-news-left__wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
    .contact-bottom-item {
        flex-direction: column;
        row-gap: 15px;
    }
    .contact-bottom-item__right {
        text-align: center;
        padding-left: 0;
        margin-left: 0;
        border-left: none;
    }
    .contact-bottom {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-around;
        margin-top: 50px;
        align-items: flex-start;
    }
    .footer-top-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .footer-right {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }
    .footer-right p {
        text-align: center;
        width: 100%;
    }
    .footer-right p a {
        display: block;
    }
    .footer__logo {
        text-align: center;
    }
    .footer-social {
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    .management__wrapper.management__wrapper--v2 .management-item__info {
        width: calc(100% - 530px);
    }
    .contact-page__wrapper .container {
        flex-direction: column;
    }
    .contact-page__wrapper .contact__left {
        max-width: 100%;
    }
    .contact-page__wrapper {
        margin-top: 40px;
        .input-block input {
            height: 50px;
        }
        .textarea-block textarea {
            height: 250px;
        }
        .contact__btn {
            height: 50px;
            margin-top: 30px;
        }
    }
    .contact-page__wrapper .contact-bottom {
        margin-top: 50px;
        max-height: inherit;
        row-gap: 40px;
    }
    .contact__right {
        padding-top: 40px;
    }
    .contact-page .contact__right iframe {
        height: 350px;
    }
    .contact-page__top {
        height: 150px;
    }
    .contact-page__top .container {
        font-size: 28px;
    }
    .map-sec-block {
        flex-direction: column;
        padding: 20px;
    }
    .map-sec-block .contact__right {
        width: 100%;
        margin-top: 20px;
    }
    .map-sec-block .contact__right iframe {
        height: 300px;
        margin-top: 30px;
    }
    .map-sec-block__title {
        font-size: 28px;
        line-height: 34px;
    }
    .map-sec__wrapper {
        margin-top: 40px;
    }
    .gallery .container .swiper-button-next,
    .gallery .container .swiper-button-prev {
        width: 40px;
        height: 40px;
    }
    .legal-acts-top__title {
        font-size: 32px;
        line-height: 38px;
    }
    .legal-acts-top__descr {
        font-size: 22px;
        margin-top: 15px;
    }
    .legal-acts-top {
        padding: 53px 0 160px 0;
        height: auto;
    }
    .legal-acts-item__title {
        font-size: 18px;
    }
    .legal-acts-item__btn {
        padding: 7px 10px;
        white-space: nowrap;
    }


}

@media (max-width: 950px) {
    .latest-news__wrapper {
        grid-template-columns: 1fr;
        row-gap: 40px;
    }
    .latest-news-left__wrapper {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 20px;
    }
    .latest-news {
        padding-top: 40px;
        margin-top: 40px;
    }
    .latest-news-right__wrapper {
        margin-top: 30px;
    }
    .partners__wrapper {
        margin-top: 40px;
    }
    .partners {
        padding: 30px 0;
    }
    .organizations-item {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .organizations-item__right {
        width: 100%;
        padding: 20px;
    }
    .organizations-item__right::before {
        left: -12px;
        top: -3px;
    }
    .organizations-item__center  {
        max-width: 490px;
    }
    .legal-acts-item {
        padding: 15px;
    }
    .legal-acts-item__title {
        font-size: 16px;
        column-gap: 10px;
    }
    .legal-acts-item__btn {
        font-size: 14px;
        height: fit-content;
    }
    .legal-acts-item__top {
        align-items: center;
    }
}

@media (max-width: 850px) {
    .contact {
        padding: 40px 0;
        margin-top: 20px;
    }
    .contact__wrapper {
        grid-template-columns: repeat(1, 1fr);
        .contact__right {
            padding-top: 40px;
        }
        .contact__right iframe {
            margin-top: 25px;
            height: 500px;
        }
        .input-block input {
            height: 50px;
        }
        .textarea-block textarea {
            height: 215px;
        }
        .contact__title {
            font-size: 28px;
        }
        .contact__subtitle {
            font-size: 18px;
        }
        .contact__btn {
            margin-top: 40px;
            height: 50px;
        }
        .contact__right span {
            font-size: 22px;
        }
    }
    .form-sec__wrapper {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 20px;
        row-gap: 20px;
    }
    .form-sec {
        margin-top: 40px;
    }
    .mt-47 {
        margin-top: 24px;
    }
    .management__wrapper.management__wrapper--v1 {
        grid-template-columns: repeat(2, 1fr);
    } 
    .news-sec__wrapper {
        grid-template-columns: repeat(2, 1fr);
    } 
    .management__wrapper.management__wrapper--v2 .management-item {
        flex-wrap: wrap;
        row-gap: 25px;
        justify-content: space-around;
    }
    .management__wrapper.management__wrapper--v2 .management-item__info {
        height: auto;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        padding: 20px;
        padding-top: 25px;
    }
    .management__wrapper.management__wrapper--v2 .management-item__info::before {
        left: -14px;
        top: -5px;
    }
    .divisions__wrapper {
        flex-direction: column;
    }
    .divisions__wrapper .tab-link-wrapper {
        width: 100%;
    }
    .divisions__wrapper .tab-content-wrapper {
        width: 100%;
        margin-top: 10px;
    }
    .divisions__wrapper .tab {
        height: 50px;
    }
    .divisions {
        padding-bottom: 0;
    }
    .modal-management {
        min-width: 90%;
        max-width: 90%;
    }
    .modal-management__body {
        padding: 50px 20px 20px 20px;
        font-size: 18px;
    }

}

@media (max-width: 760px) {
    .useful-links__block-v1 {
        grid-template-columns: repeat(2, 1fr);
    }
    .useful-links .tab-link-wrapper {
        flex-direction: column;
    }
    .useful-links .tab {
        width: 100%;
        text-align: center;
        font-size: 16px;
    }
    .useful-links__block-v2 {
        grid-template-columns: repeat(1, 1fr);
    }
    .useful-links__block-v2 .useful-links-item {
        min-height: 60px;
        font-size: 16px;
        padding: 15px 20px;
        br {
            display: none;
        }
    }
    .open-data-chart {
        grid-template-columns: repeat(1, 1fr);
    }
    .open-data-chart .my-chart {
        grid-column-start: 1;
        grid-column-end: 2;
        max-width: 340px;
    }
    .open-data-chart__item--num {
        font-size: 24px;
    }
    .open-data-chart__item {
        height: auto;
    }
    .more-statistic-block__title {
        font-size: 28px;
        line-height: 32px;
    }
    .contact-bottom-item a {
        br {
            display: none;
        }
    }
    .form-sec__bottom {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 15px;
    }
    .accardion__item--descr {
        padding-right: 30px;
    }
    .organizations__wrapper {
        row-gap: 20px;
    }
    .directory-item__left {
        font-size: 18px;
        padding: 20px;
    }
    .legal-acts-top__title {
        text-align: left;
    }
}

@media (max-width: 650px) {
    .latest-news-left__wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
    .directory-item {
        grid-template-columns: repeat(1, 1fr);
    }
    .directory {
        padding-bottom: 0;
    }
    .news-sec-item--big .news-sec-item__title {
        font-size: 24px;
    }
    .news-sec-item--big .news-sec-item__descr {
        font-size: 16px;
        margin-top: 25px;
    }
    .news-sec-item__share {
        font-size: 16px;
    }
    .news-sec-item__view {
        font-size: 16px;
    }
    .news-sec-item--big .news-sec-item__date {
        font-size: 16px;
    }
    .legal-acts-top__title {
        flex-direction: column;
        text-align: center;
        row-gap: 20px;
        font-size: 26px;
        line-height: 30px;
    }
    .legal-acts-top__descr {
        font-size: 18px;
        line-height: 120%;
    }
    .legal-acts-accardion {
        margin-top: -58px;
    }
    .legal-acts-top {
        padding: 40px 15px 90px 15px;
    }
}

@media (max-width: 550px) {
    .promo-block__link-wrap {
        flex-wrap: wrap;
        gap: 15px;
        justify-content: space-around;
    }
    .promo-left {
        flex-direction: column;
        text-align: center;
        row-gap: 10px;
        padding: 15px;
        background: linear-gradient(360deg, rgba(0, 46, 163, 0.35) 6.39%, rgba(0, 46, 163, 0.808732) 44.23%, rgba(0, 46, 163, 0.03) 99.54%);
    }
    .promo-left p {
        font-size: 18px;
        line-height: 27px;
    }
    .latest-news-right__wrapper {
        padding: 0;
        background-color: transparent;
    }
    .latest-news-left .news-sec-item {
        padding: 20px;
    }
    .latest-news-left .news-sec-item__title {
        font-size: 16px;
        margin-top: 15px;
    }
    .latest-news-left .news-sec-item__descr {
        font-size: 16px;
    }
    .latest-news-left .news-sec-item__img {
        height: 180px;
    }
    .latest-news__title {
        font-size: 24px;
    }
    .latest-news-left__top::before {
        display: none;
    }
    .latest-news-left__top {
        flex-direction: column;
        row-gap: 15px;
    }
    .title {
        font-size: 28px;
        line-height: 32px;
    }
    .contact__wrapper .contact__right iframe {
        height: 300px;
    }
    .open-data__btn {
        padding: 15px 40px;
        font-size: 18px;
    }
    .contact-bottom-item a {
        font-size: 16px;
        line-height: 21px;
    }
    .contact-bottom-item__title {
        font-size: 22px;
        line-height: 16px;
    }
    .footer-right {
        grid-template-columns: repeat(1, 1fr);
    }
    .footer-top-list {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 10px;
    }
    .footer-top {
        padding: 30px 15px 30px 15px;
    }
    .footer__logo {
        img {
            height: 120px;
        }
    }
    .management__wrapper.management__wrapper--v1 {
        grid-template-columns: repeat(1, 1fr);
        margin-top: 30px;
    } 
    .subtitle {
        font-size: 18px;
    }
    .management {
        margin-top: 40px;
    }
    .news-sec__wrapper {
        grid-template-columns: repeat(1, 1fr);
    } 
    .management__wrapper.management__wrapper--v2 {
        margin-top: 30px;
    }
    .map-sec-block__title {
        font-size: 24px;
        line-height: 26px;
    }
    .contact__right span {
        font-size: 22px;
    }
    .map-sec {
        margin-top: 40px;
    }
    .news-sec__top {
        grid-template-columns: 1fr;
    }
    .news-sec-filter {
        grid-column-start: 1;
        grid-column-end: 2;
    }
}

@media (max-width: 500px) {
    .contact-bottom {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 30px;
    }
    .partners {
        .swiper-slide img {
            width: 100%;
        }
    }
    .form-sec__block-wrap {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 10px;
    }
    .online-services-page__title {
        text-align: left;
        img {
            height: 50px;
        }
    }
    .input-block__wrap {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 10px;
    }
    .accardion__item--title {
        font-size: 18px;
        padding: 15px 20px;
        padding-right: 70px;
    }
    .accardion__item--descr {
        font-size: 16px;
        padding: 20px;
        padding-top: 0;
    }
    .management__wrapper.management__wrapper--v2 .management-item__info {
        flex-direction: column;
        text-align: center;
    }
    .management__wrapper.management__wrapper--v2 .contact-block__title {
        text-align: center;
    }
    .management__wrapper.management__wrapper--v2 .contact-block a {
        text-align: center;
    }
    .management__wrapper.management__wrapper--v2 .reception-days-block__title {
        text-align: center;
    }
    .management__wrapper.management__wrapper--v2 .reception-days-block div {
        text-align: center;
    }
    .organizations-item__img {
        width: 100%;
        margin-right: 0;
    }
    .organizations-item__name {
        margin-top: 15px;
    }
    .organizations-item__center .contact-block {
        margin-top: 15px;
    }
    .news-sec-item__view {
        margin-right: 20px;
    }
    .news-sec-item--big .news-sec-item__title {
        font-size: 22px;
        line-height: 25px;
    }
    .gallery .container {
        padding: 20px;
    }
    .modal-management__body {
        font-size: 16px;
    }
}

@media (max-width: 450px) {
    .useful-links__block-v1 {
        grid-template-columns: repeat(1, 1fr);
    }
    .useful-links__block-v1 .useful-links-item__icon {
        margin-top: 40px;
    }
    .open-data-chart .my-chart {
        max-width: 300px;
        margin-bottom: 20px;
    }
    .open-data-chart__item--title {
        font-size: 16px;
    }
}


