.directory {
    margin-top: 40px;
    padding-bottom: 30px;
}


.directory__wrapper {
    margin: 70px auto 0 auto;
    max-width: 1178px;
}

.directory-item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
    &:first-child {
        border-radius: 16px 16px 0 0;
    }
}

.directory-item__left {
    font-weight: 700;
    font-size: 20px;
    line-height: 118.19%;
    color: #292929;
    padding: 30px 35px;
    display: flex;
    align-items: center;
}

.directory-item__right {
    padding: 30px;
    background: #F2F2F2;
    a {
        display: flex;
        align-items: center;
        column-gap: 8px;
        font-weight: 400;
        font-size: 20px;
        line-height: 118.19%;
        color: #212121;
        margin-bottom: 8px;
        transition: all 0.3s ease-in-out;
        &:hover {
            text-shadow: .5px 0 0 currentColor;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    .svg {
        width: 19px;
        height: 19px;
    }
}

