.form-sec {
    max-width: 880px;
    margin: 70px auto 0 auto;
}

.form-sec__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #2F2F2F;
}

.form-sec__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    row-gap: 60px;
    margin-top: 40px;
    .input-block {
        input {
            height: 50px;
            padding: 0 20px;
        }
    }
}

.form-sec__title-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #696F79;
    display: block;
    margin-bottom: 6px;
}

.radio-wrap {
    margin-top: 23px;
    &.radio-wrap--v2 {
        >div {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 14px;
            .radio-block {
                &.active {
                    color: #2F2F2F;
                }
                input:checked {
                    border: 2px solid #2F2F2F;
                }
                input::before {
                    background-color: #2F2F2F;
                }
            }
        }
    }
    >div {
        background: #F4F4F4;
        border-radius: 6px;
        padding: 15px 20px;
        display: flex;
        align-items: center;
        column-gap: 35px;
    }
}

.input-block__wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    
}

.radio-block {
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #494949;
    cursor: pointer;
    &.active {
        color: #6E41E2;
    }
    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: rgba(255, 255, 255, 0.25);
        border: 1px solid rgba(153, 153, 153, 0.5);
        border-radius: 24px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: relative;
    }
    input::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        background: #6E41E2;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }
    input:checked {
        border: 2px solid #6E41E2;
    }
    input:checked::before {
        opacity: 1;
    }
}


.select-block {
    margin-top: 23px;
    position: relative;
    &::before {
        position: absolute;
        content: url('../img/arrow-select.svg');
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    select {
        border: 1px solid #8692A6;
        border-radius: 6px;
        height: 50px;
        font-weight: 400;
        font-size: 16px;
        line-height: 168.19%;
        color: #8692A6;
        width: 100%;
        padding: 0 20px;
        cursor: pointer;
    }
}

.phone-input {
    margin-top: 24px;
    input {
        border: 1px solid #8692A6;
        border-radius: 6px;
        width: 100%;
        height: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #8692A6;
        padding-left: 60px !important;
    }
    .iti {
        width: 100%;
    }
    .iti__selected-flag {
        padding: 0 15px;
    }
}


.file-block {
    margin-top: 23px;
}

.mt-47 {
    margin-top: 48px;
}

.input-file {
    width: 100%;
    height: 50px;
    background: #F5F6F9;
    border: 1px dashed #8692A6;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.input-file--done {
        background: #EEFFEE;
        border: 1px dashed #209920;
        position: relative;
        &::before {
            position: absolute;
            content: url('../img/check.svg');
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        p {
            color: #209920;
        }
    }
    &.input-file--error {
        background: #F9E3E3;
        border: 1px dashed #E70000;
        p {
            color: #F25252;
        }
    }
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #898989;
    }
    input {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}


.form-sec__block-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 6px;
    .input-block {
        margin-top: 0;
    }
}


.data-block {
    border: 1px solid #8692A6;
    border-radius: 6px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
    column-gap: 7px;
    div {
        width: 22px;
        height: 23px;
        background-image: url('../../img/date-icon.svg');
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            left: 0;
            top: 0;
            cursor: pointer;
        }
    }
    .data-block__val {
        font-weight: 400;
        font-size: 16px;
        line-height: 168.19%;
        color: #8692A6;
    }
}

[type="date"] {
    width: 100%;
    position: relative;
}

[type="date"]::-webkit-calendar-picker-indicator {
    width: calc(100% - 0.25em);
    left: -1.5em;
    background-position: 100% 0;
    position: absolute;
    height: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: none;
}

.form-sec__bottom {
    margin-top: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #696F79;
    }
}

.form-sec__btn {
    background: #FFFFFF;
    border: 1px solid #BBBFC1;
    border-radius: 6px;
    padding: 15px 11px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    margin: 65px auto 0 auto;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #ececec;
    }
    &::before {
        content: '';
        background-image: url('../../img/save.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
        display: block;
    }
}